import React, { useEffect, useRef, useState } from 'react'
import styles from "./emailcampaign.module.css";
import { AppBar, Box, Button, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Popover, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AdminCustomTable from '../../component/adminCustomTable/AdminCustomTable';
import EmailCampaignFilter from './emailCampaignFilter';
import { styled, css } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import LinkIcon from "@mui/icons-material/Link";
import { Link } from 'react-router-dom';


const StatusValue = [
    { status: 'AWAIT' },
    { status: 'APPROVED' },
    { status: 'SENT' },
    { status: 'FAILED' },
    { status: 'LATER' },
    { status: 'SEND_NOW' },
];

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const EmailCampaign = ({ topvalue }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [text, setText] = useState(null);
    const [domain, setDomain] = useState({ text: '', file: '', recipients: '' });
    const [value, setValue] = useState(0);
    const [cursor, setCursor] = useState(1);
    const [nextpage, setNextpage] = useState(false);
    const [filterExpand, setFilterExpand] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [searchData, setSearchData] = useState();
    const [selectedHead, setSelectedHead] = useState([
        `Subject`,
        `Body`,
        'Industry',
        'Company_Name',
        `Website`,
        `Department`,
        `Day`,
        `Recipient_Email`,
        `Status`,
        `Start_Time`,
        `Created_By`,
        `Created_Time`,
        `Modified_Time`,
        `Modified_By`]);

    const [dataDetails, setDataDetails] = useState([]);
    const [checkedId, setCheckedId] = useState([])
    const [limit, setLimit] = useState(30);
    const [type, setType] = useState("0");
    const templatefileInput = useRef(null);
    const [open, setOpen] = useState(false);
    const [required, setRequired] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [createValue, setCreateValue] = useState({ days: '', subject: '', body: '', department: '', industry: '', website: '', company_name: '', recipient_email: '' });
    const [errors, setErrors] = useState({});



    const orgtHead = [`Subject`, `Body`, 'Industry',
        'Company_Name',
        `Website`,
        `Department`,
        `Day`,
        `Recipient_Email`,
        `Status`,
        `Start_Time`,
        `Created_By`,
        `Created_Time`,
        `Modified_Time`,
        `Modified_By`];


    const DateFormat = (unix) => {
        let getDate = moment(new Date(unix));
        let startOfToday = moment().startOf('day');
        let startOfDate = moment(getDate).startOf('day');
        let daysDiff = startOfDate.diff(startOfToday, 'days');
        let days = {
            '0': 'Today',
            '-1': 'Yesterday',
            '1': 'Tomorrow'
        };

        if (Math.abs(daysDiff) <= 1) {
            let day = days[daysDiff];
            let time = moment(new Date(unix)).format('LT')
            return day + ' ' + time;
        } else {
            return moment(new Date(unix)).format('DD/MM/YYYY LT');
        }
    };

    const submitFile = () => {
        let form = {
            text: domain.text,
            file: domain.file,
            recipients: domain.recipients
        };


        const formData = new FormData();

        Object.keys(form).forEach((key) => {
            formData.append(key, form[key]);
            if (form[key] === '') formData.delete(key);
        });

        dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_EMAILCAMPAIGN_AICONTENT, formData, { headers: { "Content-Type": "multipart/form-data" }, withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                if (res.data.already_exists?.includes(domain.text)) {
                    toast.error(`These domains have already been used ${res.data.already_exists.map((domain) => domain)}`);
                }
                if (res.data?.end_time) {
                    toast.info(`This scheduled task will be completed at nearly ${DateFormat(res.data.end_time)},"Note: The end time is an optional parameter. In some cases, this parameter may not be present in the response. Handle this case also"`)
                }
                else
                    toast.success("Success");
            })
            .catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            });
    }



    const handleFilterData = (fdata) => {
        setFilterData(fdata);
        setCursor(1);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const getValues = () => {
        setFilterData([]);
        getData()
    }
    const getData = (datas) => {
        if (topvalue === 6) {
            let data = {
                limit: limit,
                cursor: cursor,
                filters: datas ? datas : filterData,
                search: searchData
            }
            for (let key in data) {
                if (data[key] === 0) {
                    delete data[key];
                }
            }
            setFilterExpand(true)
            dispatch(toggleLoader(true));
            axios.post(process.env.REACT_APP_EMAILCAMPAIGN, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    if (res.data.next_cursor) {
                        setNextpage(true)
                    } else {
                        setNextpage(false)
                    }
                    dispatch(toggleLoader(false));

                    const data = res.data.data.map((o) => {
                        const { Status, ...restData } = o;
                        const data = JSON.parse(o.formated_date_info);
                        return {
                            ...restData,
                            Status: StatusValue[Status].status,
                            // Start_Time: data.Start_Time,
                            // Modified_Time: data.Modified_Time,
                            // Created_Time: data.Created_Time
                        };
                    });
                    setDataDetails(data);
                    toast.success(res.message);
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);
                });
        }
    }

    const handleCourserData = (type) => {
        if (type === 'increment') {
            setCursor(cursor + 1);

        } else if (type === 'decrement') {
            setCursor(cursor - 1);
        }
    }

    const handleLimitData = (val) => {
        setLimit(val);
        setCursor(1);
    }

    const handleCheckedId = (checkedId, e) => {
        setCheckedId(checkedId)
        let data = {
            ids: checkedId,
            limit: limit,
            cursor: cursor,
        }
        dispatch(toggleLoader(true));
        if (e.modalValue === 'Aprove') {
            axios.post(process.env.REACT_APP_EMAILCAMPAIGN_APPROVE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    const data = res.data.data.map((o) => {
                        const { Status, ...restData } = o;

                        return {
                            ...restData,
                            Status: StatusValue[Status].status,
                        };
                    });
                    setDataDetails(data);
                    toast.success('Aproved');
                    dispatch(toggleLoader(false));
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);
                });
        }
        else if (e.modalValue === 'Delete') {
            let data = {
                ids: checkedId,
                limit: limit,
                cursor: cursor,
            }
            dispatch(toggleLoader(false));
            axios.delete(process.env.REACT_APP_EMAILCAMPAIGN, { withCredentials: process.env.REACT_APP_CREDENTIALS, data: data })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    const data = res.data.data.map((o) => {
                        const { Status, ...restData } = o;
                        const data = JSON.parse(o.formated_date_info);
                        return {
                            ...restData,
                            Status: StatusValue[Status].status,
                        };
                    });
                    setDataDetails(data);
                    toast.success('Deleted');
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);
                });
        }
        else if (e === 'schedule') {

            let data = {
                ids: checkedId,
                limit: limit,
                cursor: cursor,
            }
            dispatch(toggleLoader(false));
            axios.post(process.env.REACT_APP_EMAILCAMPAIGN_SCHEDULENOW, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    const data = res.data.data.map((o) => {
                        const { Status, ...restData } = o;

                        return {
                            ...restData,
                            Status: StatusValue[Status].status,
                        };
                    });
                    setDataDetails(data);
                    toast.success('Scheduled');

                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);

                });
        }
        else if (e === 'later') {

            let data = {
                ids: checkedId,
                limit: limit,
                cursor: cursor,
            }
            dispatch(toggleLoader(false));
            axios.put(process.env.REACT_APP_EMAILCAMPAIGN_APPROVE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    const data = res.data.data.map((o) => {
                        const { Status, ...restData } = o;
                        const data = JSON.parse(o.formated_date_info);
                        return {
                            ...restData,
                            Status: StatusValue[Status].status,
                            Start_Time: data.Start_Time,
                            Modified_Time: data.Modified_Time,
                            Created_Time: data.Created_Time
                        };
                    });
                    setDataDetails(data);
                    toast.success('Later added');
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);
                });
        }

    }
    const handleCheckedValue = (checkedId, checkedValue, e) => {
        let data = {
            id: checkedValue.Id,
            subject: checkedValue.Subject,
            body: checkedValue.Body,
            industry: checkedValue.Industry,
            website: checkedValue.website,
            company_name: checkedValue.Company_Name,
            department: checkedValue.Department,
            recipient_email: checkedValue.Recipient_Email,
        }
        dispatch(toggleLoader(true));
        axios.put(process.env.REACT_APP_EMAILCAMPAIGN, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success('Edited');
            })
            .catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            });
    }
    const handleExport = () => {
        let data = {
            limit: limit,
            cursor: cursor

        }
        dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_EMAILCAMPAIGN_EXPORT, data, { responseType: 'blob', withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                const href = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', "emailcampaignexport.xls");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(href);
                toast.success('Downloaded');
            })
            .catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            });
    }

    useEffect(() => {
        if (filterData.length > 0) {
            getData();
        }

    }, [filterData, topvalue]);

    useEffect(() => {
        getData([]);
    }, [cursor, limit, searchData]);


    const setCheckedHead = (head) => {
        setSelectedHead(head)
    }

    const selectedFile = (e) => {
        const file = e.target.files[0];
        if (!(file['type'].includes('ms-excel') || file['type'].includes('csv'))) {
            toast.error('Invalid file format. Please upload a valid file in the appropriate format (e.g.xls,.csv).')
        } else if (file.size > 10e6) {
            toast.error('The file you are trying to upload is too large. The maximum file size allowed is 10 MB.');
        } else {
            setDomain({ ...domain, file: e.target.files[0], text: '' })
        };
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(createValue);
        handleClose()
        let data = {
            days: createValue.days,
            subject: createValue.subject,
            body: createValue.body,
            department: createValue.department,
            industry: createValue.industry,
            website: createValue.website,
            company_name: createValue.company_name,
            recipient_email: createValue.recipient_email,
        }

        if (Object.keys(validationErrors).length === 0) {
            axios.post(process.env.REACT_APP_EMAILCAMPAIGN_CREATE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    toast.success("Email Campaign Created");
                    setErrors({});
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    if (err?.response?.data?.recipient_email) {
                        toast.error(err.response.data.recipient_email);
                    }
                    else toast.error(err.message);
                });
        }
        else {
            setErrors(validationErrors);
        }
    }
    const validateForm = (data) => {
        let errors = {};
        if (!data.days.trim()) {
            errors.days = 'days is required';
        }
        if (!data.subject.trim()) {
            errors.subject = 'subject is required';
        }
        if (!data.body.trim()) {
            errors.body = 'body is required';
        }
        if (!data.website.trim()) {
            errors.website = 'website is required';
        }
        if (!data.recipient_email.trim()) {
            errors.recipient_email = 'recipient_email is required';
        }

        return errors;
    };


    return (
        <div>
            <div style={{ paddingTop: "10px", width: '100%', height: '100%', overflow: 'hidden' }}>
                {value === 1 && (<div>
                    <EmailCampaignFilter filterExpand={filterExpand} orgtHead={orgtHead} filterdata={handleFilterData} />
                </div>)}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <AppBar sx={{ width: 184, backgroundColor: 'white', color: 'black', borderRadius: '5px' }} position="static">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                        >
                            <Tab label="Create" {...a11yProps(0)} />
                            <Tab label="View" {...a11yProps(1)} onClick={() => getValues()} />
                        </Tabs>
                    </AppBar>
                    {value === 1 && (< Box className={styles.create_btn}>
                        <Box sx={{ display: 'flex', gap: "10px" }}>
                            <Button
                                variant='outlined'
                                component="label"
                                onClick={handleExport}
                            >
                                Export Excel
                            </Button>
                            <Button
                                variant="contained"
                                component="label"
                                onClick={handleOpen}
                            >
                                Create
                            </Button>
                        </Box>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{ ...style, width: 600 }}>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Days<span style={{ color: 'red' }}>*</span></Box>
                                    <FormControl size="small" >
                                        <OutlinedInput error={errors.days} type='number' onChange={(e) => setCreateValue({ ...createValue, days: e.target.value })} style={{ height: 35, width: '250px' }} placeholder='Enter Days' />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Subject<span style={{ color: 'red' }}>*</span></Box>
                                    <FormControl size="small" >
                                        <OutlinedInput error={errors.subject} onChange={(e) => setCreateValue({ ...createValue, subject: e.target.value })} style={{ height: 35, width: '250px' }} placeholder='Enter Subject' />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Body<span style={{ color: 'red' }}>*</span></Box>
                                    <FormControl size="small" >
                                        <OutlinedInput error={errors.body} onChange={(e) => setCreateValue({ ...createValue, body: e.target.value })} style={{ height: 35, width: '250px' }} placeholder='Enter Body' />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Department</Box>
                                    <FormControl size="small" >
                                        <OutlinedInput onChange={(e) => setCreateValue({ ...createValue, department: e.target.value })} style={{ height: 35, width: '250px' }} placeholder='Enter Department' />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Industry</Box>
                                    <FormControl size="small" >
                                        <OutlinedInput onChange={(e) => setCreateValue({ ...createValue, industry: e.target.value })} style={{ height: 35, width: '250px' }} placeholder='Enter Industry' />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Website <span style={{ color: 'red' }}>*</span> </Box>
                                    <FormControl size="small" >
                                        <OutlinedInput error={errors.website} onChange={(e) => setCreateValue({ ...createValue, website: e.target.value })} style={{ height: 35, width: '250px' }} placeholder='Enter Website' />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Company Name</Box>
                                    <FormControl size="small" >
                                        <OutlinedInput onChange={(e) => setCreateValue({ ...createValue, company_name: e.target.value })} style={{ height: 35, width: '250px' }} placeholder='Enter Company Name' />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Recipient Email <span style={{ color: 'red' }}>*</span> </Box>
                                    <FormControl size="small" >
                                        <OutlinedInput error={errors.recipient_email} onChange={(e) => setCreateValue({ ...createValue, recipient_email: e.target.value })} style={{ height: 35, width: '250px' }} placeholder='Enter Recipient Email' />
                                    </FormControl>
                                </Box>
                                <Box className={styles.submit_btn}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </Modal>
                    </Box>)}
                </Box>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <Box sx={{ padding: '50px', width: '100%', height: '100%' }}>
                        <Box sx={{ width: "30%" }}>
                            <Box sx={{ display: 'flex', gap: "5px" }}>
                                <FormControl sx={{ width: "322px" }}>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={type}
                                        label="Type"
                                        onChange={(e) => setType(e.target.value)}
                                    >
                                        <MenuItem value="0" >Text</MenuItem>
                                        <MenuItem value="1" >File</MenuItem>
                                    </Select>
                                </FormControl>
                                {type === "1" && (<Link style={{ width: '10%', paddingTop: '5px', textDecoration: 'none' }} target="_blank"
                                    to="https://docs.google.com/spreadsheets/d/11mONioWjKP6gNhF4Q8ELyg-AlU5ovvSQTj5O7USf55E/edit?usp=sharing">Sample link</Link>)}

                            </Box>
                            <Box style={{
                                display: "flex", gap: '15px', paddingTop: '20px', flexDirection: "column"
                            }}>
                                {type === "0" && (<Box sx={{ display: "contents" }}>
                                    <FormControl className={styles.controler}>
                                        <Textarea
                                            className={styles.selector_domain}
                                            placeholder='Ex:Centilio.com'
                                            label="Domain"
                                            onChange={(e) => setDomain({ ...domain, text: e.target.value, file: '' })}>
                                        </Textarea>
                                    </FormControl>
                                    <FormControl sx={{ paddingTop: '20px' }}>
                                        <Textarea
                                            className={styles.selector_domain}
                                            placeholder='Recipinets'
                                            label="Recipinets"
                                            onChange={(e) => setDomain({ ...domain, recipients: e.target.value })}>
                                        </Textarea>
                                    </FormControl>
                                </Box>)}
                                {type === "1" && (<FormControl className={styles.controler}>
                                    < div style={{ width: "317px" }}>

                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: "100%", height: "60px"
                                            }}
                                            startIcon={<LinkIcon />}
                                            className={styles.btn}
                                            component="label"
                                            onChange={selectedFile}
                                            onClick={() => templatefileInput.current.click()}
                                        >
                                            Upload your File
                                        </Button>
                                        <input
                                            ref={templatefileInput}
                                            type="file"
                                            id="upload-doc"
                                            style={{ display: "none" }}
                                            onChange={(e) => selectedFile(e)}
                                        />
                                    </div >

                                </FormControl>)}

                                <div style={{ paddingTop: '20px' }}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        onClick={() => submitFile()}
                                        disabled={domain.text || domain.file ? false : true}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Box>
                        </Box >
                    </Box >
                    <Box>
                        <AdminCustomTable
                            cursors={cursor}
                            nextpage={nextpage}
                            courserData={handleCourserData}
                            limitData={handleLimitData}
                            searchName='Datas'
                            keys={selectedHead}
                            head={selectedHead}
                            filterHead={orgtHead}
                            getCheckedHead={(head) => setCheckedHead(head)}
                            body={dataDetails}
                            getCheckedId={(checkedId, e) => handleCheckedId(checkedId, e)}
                            emailCampaign="EmailCampaign"
                            getCheckedValue={(checkedId, checkedValue, e) => handleCheckedValue(checkedId, checkedValue, e)}
                            filterdata={handleFilterData}
                            SearchDomain={(domain) => setSearchData(domain)}
                        />
                    </Box>
                </SwipeableViews>
            </div >
        </div >
    )
}

export default EmailCampaign