import { AppBar, Box, Button, FormControl, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from "./AdminTool.module.css";
import Filter from './Filter';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import "./admin.css";
import AdminCustomTable from '../component/adminCustomTable/AdminCustomTable';
import { useNavigate } from 'react-router-dom';
import AllowFeature from './AllowFeature';
import Signup from './Signup';
import Reschedule from './Reschedule';
import PlanAccess from './PlanAccess';
import Banner from './Banner';
import EmailCampaign from '../Admin/EmailCampaign/emailcampaign';
import { admin_account_details } from '../store/adminDetails/adminAccountAction';
import { getadminaccountdata, getadmindata } from '../Api/AdminDetailsApi';
import { useSelector } from 'react-redux';
import { admin_details } from '../store/adminDetails/adminDetailsAction';
import PromoCode from './PromoCode/PromoCode.js';
import AllUsers from './AllUsers/AllUsers.js';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const AdminTool = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [type, setType] = useState(1);
  const [id, setId] = useState("");
  const [userId, setUserId] = useState(1);
  const [limit, setLimit] = useState(50);
  const [orgIds, setOrgIds] = useState([]);
  const [orgId, setOrgId] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const [checkedId, setCheckedId] = useState([]);

  const [tHead, setTHead] = useState([]);
  const [orgtHead, setOrgTHead] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [dataDetails, setDataDetails] = useState([]);
  const [orgHeadData, setOrgHeadData] = useState([]);
  const [addOrgHeadData, setaddOrgHeadData] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [selectedTableData, setSelectedTableData] = useState(0);

  const [value, setValue] = useState(0);
  const [topvalue, setTopValue] = useState(0);
  const [filterExpand, setFilterExpand] = useState(false);
  const [nextpage, setNextpage] = useState(false);
  const [cursor, setCursor] = useState(1);
  const [featureAllowInfo, setFeatureAllowInfo] = useState('');
  const [bannerInfo, setBannerInfo] = useState('');
  const [types, setTypes] = useState('Account');
  const tableInfo = useSelector((state) => state?.adminAccountDetails);
  const admintableInfo = useSelector((state) => state?.adminDetails);


  useEffect(() => {
    setOrgTHead([]);
    dispatch(toggleLoader(true));

    getadmindata((res) => {    //admintableInfo api call
      dispatch(admin_details(res));
      dispatch(toggleLoader(false));
      const tableName = res.data.table_names
      const options = JSON.parse(res.data.feature_allow_info);
      const banner = JSON.parse(res.data.banner_info);
      setBannerInfo(banner)
      setFeatureAllowInfo(options)
      if (types === 'Sign') {
        setTableData(tableName)
        const data = res?.data?.tables
        Object.keys(data).forEach(key => {
          if (data[key][tableName[selectedTableData]]) {
            let OrgUsersTableHead = data[key][tableName[selectedTableData]];
            setOrgHeadData(OrgUsersTableHead)
          }
        });
      }
    }, (err) => {
      navigate("/error-404");
      dispatch(toggleLoader(false));
      toast.error(err.message);
    })

    getadminaccountdata((res) => {  //tableInfo api call
      dispatch(admin_account_details(res));
      const tableName = res.data.table_names
      let UserTableHead = type === 3 ? res.data.tables[2]?.OrgUsers : res.data.tables[0].User
      let OrgUsersTableHead = type === 3 ? res.data.tables[1]?.Org : res.data.tables[2].OrgUsers
      setTHead(UserTableHead);
      setOrgTHead(OrgUsersTableHead);
      if (types === 'Account') {
        setTableData(tableName)
        const data = res.data.tables
        Object.keys(data)?.forEach(key => {
          if (data[key][tableName[selectedTableData]]) {
            let OrgUsersTableHead = data[key][tableName[selectedTableData]];
            setOrgHeadData(OrgUsersTableHead)
          }
        });
      }
      dispatch(toggleLoader(false));

    }, (err) => {
      dispatch(toggleLoader(false));
      toast.error(err.message);
    })
  }, []);

  useEffect(() => {
    if (admintableInfo?.admintableinfo?.table_names) {
      const tableName = admintableInfo?.admintableinfo?.table_names
      const options = JSON.parse(admintableInfo?.admintableinfo?.feature_allow_info);
      const banner = JSON.parse(admintableInfo?.admintableinfo?.banner_info);
      setBannerInfo(banner)
      setFeatureAllowInfo(options)
      if (types === 'Sign') {
        setTableData(tableName)
        const data = admintableInfo?.admintableinfo?.tables
        Object.keys(data).forEach(key => {
          if (data[key][tableName[selectedTableData]]) {
            let OrgUsersTableHead = data[key][tableName[selectedTableData]];
            setOrgHeadData(OrgUsersTableHead)
          }
        });
      }
    }
  }, [selectedTableData, types])


  useEffect(() => {
    if (tableInfo?.tabledata?.table_names) {
      const tableName = tableInfo?.tabledata?.table_names
      let UserTableHead = type === 3 ? tableInfo?.tabledata?.tables[2]?.OrgUsers : tableInfo?.tabledata?.tables[0].User
      let OrgUsersTableHead = type === 3 ? tableInfo?.tabledata?.tables[1]?.Org : tableInfo?.tabledata?.tables[2].OrgUsers
      setTHead(UserTableHead);
      setOrgTHead(OrgUsersTableHead);
      if (types === 'Account') {
        setTableData(tableName)
        const data = tableInfo?.tabledata?.tables
        Object.keys(data)?.forEach(key => {
          if (data[key][tableName[selectedTableData]]) {
            let OrgUsersTableHead = data[key][tableName[selectedTableData]];
            setOrgHeadData(OrgUsersTableHead)
          }
        });
      }
      dispatch(toggleLoader(false));
    }
  }, [type, orgId, types, selectedTableData])


  useEffect(() => {
    if (tableData?.length > 0) handleTableHead([])
  }, [cursor]);

  const handleSetType = (e) => {
    setType(e.target.value)
    setId('');
  }

  const submitHandle = (e) => {
    if ('Enter' === e.key) {
      usersDetails()
    }
  }

  const usersDetails = () => {
    setFilterExpand(false);
    let data = { type: type, id: id, }
    dispatch(toggleLoader(true));

    axios.get(process.env.REACT_APP_USER_USER_INFO, { params: data, withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        setUserId(res?.data?.user_info[0].Id);
        setOrgIds(res?.data?.org_ids)
        setOrgId(res?.data?.org_ids[0])
        dispatch(toggleLoader(false));


        const user_info = res?.data?.user_info.map((o, i) => {
          const {
            Id,
            ...restData
          } = o;
          return {
            ...restData,
            Id: Id,
          };
        });

        const data = res?.data?.data.map((o, i) => {
          const {
            Id,
            ...restData
          } = o;

          return {
            ...restData,
            Id: Id,
          };
        });
        setUserDetails(user_info);
        setDataDetails(data);
      })

      .catch((err) => {
        setFilterExpand(false)
        toast.error(err.message);
        dispatch(toggleLoader(false))
      })
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const topHandleChange = (event, newValue) => {
    setTopValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const topHandleChangeIndex = (index) => {
    setTopValue(index);
  };

  const handleSetTableData = (e) => {
    setSelectedTableData(e.target.value)
  };

  const handleTableHead = () => {
    let data = { table: tableData[selectedTableData], limit: Number(limit), cursor: cursor, org_id: orgId, filters: filterData };
    dispatch(toggleLoader(true));

    let Api = ''
    if (types === 'Account') {
      Api = process.env.REACT_APP_QUERY_ACCOUNT
    }
    else Api = process.env.REACT_APP_QUERY

    axios.post(Api, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {

        if (res?.data?.next_cursor) {
          setNextpage(true)
        } else {
          setNextpage(false)
        }
        setOrgTHead(orgHeadData)
        setFilterExpand(true)
        dispatch(toggleLoader(false));
        const data = res?.data?.data.map((o, i) => {
          const {
            Id,
            ...restData
          } = o;

          return {
            ...restData,
            Id: Id,
          };
        });
        setDataDetails(data);

      }).catch((err) => {
        dispatch(toggleLoader(false));
        toast.error(err.message);
      })
  }

  const handleFilterData = (fdata) => {
    setFilterData(fdata);
  }

  const handleCourserData = (type) => {
    if (type === 'increment') {
      setCursor(cursor + 1);

    } else if (type === 'decrement') {
      setCursor(cursor - 1);
    }
  }

  const handleLimitData = (val) => {
    setLimit(val);
  }

  return (
    <Box sx={{ padding: '10px' }}>
      <AppBar sx={{ width: '100%', backgroundColor: 'white', color: 'black', borderRadius: '5px' }} position="static">
        <Tabs
          value={topvalue}
          onChange={topHandleChange}
          indicatorColor="secondary"
          textColor="inherit"
        >
          <Tab label="Table Info" {...a11yProps(0)} />
          <Tab label="Allow Feature" {...a11yProps(1)} />
          <Tab label="Sign Up" {...a11yProps(2)} />
          <Tab label="Reschedule" {...a11yProps(3)} />
          <Tab label="Plan Access" {...a11yProps(4)} />
          <Tab label="Banner" {...a11yProps(5)} />
          <Tab label="EmailCampaign" {...a11yProps(6)} />
          <Tab label="Promo Code" {...a11yProps(7)} />
          {tableInfo.tabledata.grand_admin_access && < Tab label="All Users" {...a11yProps(8)} />}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={topvalue}
        onChangeIndex={topHandleChangeIndex}
      >
        <Box>
          {value === 0 || topvalue === 1 ? <div></div> : <Filter filterExpand={filterExpand} orgtHead={orgtHead} filterdata={handleFilterData} />}
          {tableData.length > 0 ? <Box className={styles.admin_tool_top}>
            {value === 0 ? <Box sx={{ display: 'flex', justifyContent: 'space-between' }}><Box sx={{ display: 'flex', gap: '29px' }}>
              <FormControl size="small" >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  onChange={(e) => handleSetType(e)}
                  className={styles.admin_}
                  style={{ height: 35, width: '100px' }}
                >
                  <MenuItem value={1}>Email</MenuItem>
                  <MenuItem value={2}>User Id</MenuItem>
                  <MenuItem value={3}>Org Id</MenuItem>
                </Select>
              </FormControl>

              <TextField
                style={{ height: '15px', width: '275px' }}
                type={type === 1 ? 'email' : 'number'}
                placeholder={type === 1 ? "Email" : type === 2 ? "User id" : "Org id"}
                onKeyDown={(e) => submitHandle(e)}
                onChange={(e) => setId(e.target.value)}
                value={id} id="outlined-basic"
                variant="outlined" />
            </Box>
              <Box sx={{ display: 'flex', gap: '30px' }}>
                <Box sx={{ paddingTop: '5px', width: '100px', textAlign: 'center' }} >Org Id:</Box>
                <FormControl size="small" >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={orgId}
                    onChange={(e) => setOrgId(e.target.value)}
                    style={{ height: 35, width: '150px' }}
                    disabled={orgIds.length >= 1 ? false : true}
                  >
                    {orgIds?.map((table, i) => (<MenuItem key={i} value={table}>{table}</MenuItem>))}
                  </Select>
                </FormControl>
              </Box></Box> :
              <Box sx={{ display: 'flex', gap: '29px' }}></Box>}
            <Box className='table_div'>
              <Box sx={{ paddingTop: '15px' }}>
                <Box>
                  <AppBar sx={{ width: 210, backgroundColor: 'white', color: 'black', borderRadius: '5px' }} position="static">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="secondary"
                      textColor="inherit"
                    >
                      <Tab label="User info" {...a11yProps(0)} />
                      <Tab label="Data info" {...a11yProps(1)} disabled={orgId ? false : true} />
                    </Tabs>
                  </AppBar>

                </Box>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <AdminCustomTable
                      searchName='User Details'
                      table='user'
                      keys={tHead}
                      head={tHead}
                      body={userDetails}
                      getCheckedId={setCheckedId}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <Box sx={{ paddingTop: '15px', display: 'flex', gap: '29px' }}>
                      < FormControl size="small" >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={types}
                          onChange={(e) => setTypes(e.target.value)}
                          sx={{ height: 35, width: '150px' }}
                        >
                          <MenuItem value={'Account'}>Account</MenuItem>
                          <MenuItem value={'Sign'}>Sign</MenuItem>
                        </Select>

                      </FormControl>
                      < FormControl size="small" >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedTableData}
                          onChange={(e) => handleSetTableData(e)}
                          sx={{ height: 35 }}
                        >
                          {tableData?.map((table, i) => (<MenuItem key={i} value={i}>{table}</MenuItem>))}
                        </Select>

                      </FormControl>
                      <TextField style={{ height: 35 }} onChange={(e) => setLimit(e.target.value)} value={limit} placeholder='limits' id="outlined-basic" variant="outlined" />
                      <Box>
                        <Button style={{ height: 35 }} variant="contained" onClick={() => handleTableHead()}>Submit</Button>
                      </Box>
                    </Box>
                    <AdminCustomTable
                      cursors={cursor}
                      nextpage={nextpage}
                      courserData={handleCourserData}
                      limitData={handleLimitData}
                      searchName='Datas'
                      keys={orgtHead}
                      head={orgtHead}
                      body={dataDetails}
                      getCheckedId={setCheckedId}
                    />
                  </TabPanel>
                </SwipeableViews>
              </Box>
            </Box>
          </Box> : <Box />}
        </Box >
        <AllowFeature orgId={orgId} orgIds={orgIds} changeOrg={(org) => { setOrgId(org) }} featureAllowInfo={featureAllowInfo} />
        <Signup userId={userId} />
        <Reschedule />
        <PlanAccess orgId={orgId} />
        <Banner userId={userId} bannerInfo={bannerInfo} />
        <EmailCampaign topvalue={topvalue} />
        <PromoCode />
        <AllUsers topvalue={topvalue} />
      </SwipeableViews >
    </Box >
  )
}

export default AdminTool