import { Box, Button, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { toast } from 'react-toastify';

const AllowFeature = ({ orgId, changeOrg, orgIds, featureAllowInfo }) => {

    const dispatch = useDispatch();
    const [featureType, setFeatureType] = useState(4);
    const [actiontype, setActiontype] = useState(0);

    const featureAllow = () => {

        let data = {
            org_id: orgId,
            feature_type: featureType,
        }

        axios.post(process.env.REACT_APP_FEATUREALLOW, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success("Success");
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            })
    }

    const removeOrg = () => {
        let data = {
            org_id: orgId,
            feature_type: featureType,
            action_type: actiontype,
        }

        axios.delete(process.env.REACT_APP_FEATUREALLOW, {
            data
        })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success("Success");
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            })
    }

    const featureChange = () => {
        if (actiontype === 0) {
            featureAllow()
        }
        else removeOrg()
    }

    return (
        <Box sx={{ padding: "40px", width: '575px' }}>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Org Id:</Box>
                <FormControl size="small" >
                    <OutlinedInput type='Number' value={orgId} onChange={(e) => changeOrg(e.target.value)} style={{ height: 35, width: '250px' }} placeholder='Enter Org Id"' />
                </FormControl>
            </Box>
            {/* <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Org Id: </Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={orgId}
                        onChange={(e) => changeOrg(e.target.value)}
                        style={{ height: 35, width: '250px' }}
                        disabled={orgIds.length >= 1 ? false : true}
                    >
                        {orgIds?.map((table, i) => (<MenuItem key={i} value={table}>{table}</MenuItem>))}
                    </Select>
                </FormControl> 
            </Box> */}
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Feature Type </Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={featureType}
                        onChange={(e) => setFeatureType(Number(e.target.value))}
                        style={{ height: 35, width: '250px' }}
                    >
                        {Object.keys(featureAllowInfo).map((key, index) =>
                            (<MenuItem key={index} value={featureAllowInfo[key]}>{key}</MenuItem>)
                        )}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Action type</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={actiontype}
                        onChange={(e) => setActiontype(e.target.value)}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={0}>ENABLE</MenuItem>
                        <MenuItem value={1}>ENABLE ALL</MenuItem>
                        <MenuItem value={2}>DELETE</MenuItem>

                    </Select>
                </FormControl>
            </Box>
            <Box sx={{
                textAlign: 'end', padding: '20px'
            }}>
                <Button
                    variant="contained"
                    onClick={featureChange}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    )
}

export default AllowFeature