import React, { useState } from 'react';
import { tHead } from '../../store/contacts/contactsUtils';
import { useFormik } from 'formik';
import styles from './contacts.module.css';
import CustomTable from '../../component/customTable/CustomTable';
import { Box, debounce } from '@mui/material';
import AppHeader from '../../component/appHeader/AppHeader';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import DetailedContact from './DetailedContact/DetailedContact';
import { toast } from 'react-toastify';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { useEffect } from 'react';
import ContactTextField from './ContactTextField';
import { LimitModal } from '../../component/LimitModal';
import Skeleton from '@mui/material/Skeleton';

const Contacts = () => {
  const dispatch = useDispatch();

  const document = useSelector((state) => state?.document?.value);
  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');



  const [checkedId, setCheckedId] = useState([]);
  const [toggleNewContact, setToggleNewContact] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [contactValues, setContactValues] = useState(null);
  const [show, setShow] = useState(false);
  const [pageShow, setPageShow] = useState(false);
  const [pageIndex, setPageIndex] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(false);

  const keys = ['Email_Id', 'contactName', 'Created_User_Name', 'Created_Time'];

  useEffect(() => {
    fetchContact();
  }, []);

  const fetchContact = (idx = 0, size = 10, val, key) => {
    dispatch(toggleLoader(true));
    setShow(false);
    axios.get(process.env.REACT_APP_CONTACT, {
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: {
        org_id: document?.OrgId,
        limit: size ?? 10,
        ...(idx > 0 ? { cursor: key ? idx : idx + 1 } : { cursor: 1 }),
        ...(val?.length > 0 ? { search: val } : {}),
      },
    })
      .then((res) => {
        setContacts(
          res.data.contacts_list.map((item) => {
            const { First_Name, Last_Name, ...restData } = item;
            return {
              ...restData,
              Created_Time: item.Created_Time,
              contactName: `${First_Name} ${Last_Name ? Last_Name : ''}`,
              First_Name,
              Last_Name,
            };
          })
        );
        if (idx === 0) {
          setTotalRecord(res.data.total_record);
        }
        setPageShow(true)

        dispatch(toggleLoader(false))
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      })
    //  .finally(()=>dispatch(toggleLoader(false)))
  };

  const debouncedSendRequest = debounce(fetchContact, 800);

  const handleSearch = (idx = 0, size = 10, val) => {
    const encode = encodeURIComponent(val);
    debouncedSendRequest(idx = 0, size = 10, encode);
  };

  const contactDetailScheme = yup.object().shape({
    email_id: yup.string().required('Enter email').email('Enter valid email').matches(/^\S+@\S+\.\S+$/, "Invalid email"),
    first_name: yup.string().required('FirstName  is required'),
  });

  const contactDetail = useFormik({

    initialValues: {
      first_name: '',
      last_name: '',
      email_id: '',
      phone_number: '',
      mobile_number: '',
      org_id: document?.OrgId,
    },
    enableReinitialize: true,
    validationSchema: contactDetailScheme,
    onSubmit: (values) => {
      let validate = true;
      contacts?.map((list, i) => {
        if (values.email_id === list.Email_Id) {
          toast.error('Contact has been already added');
          validate = false;
        }
      })

      if (validate) {
        dispatch(toggleLoader(true));
        setLoading(true);
        axios.post(process.env.REACT_APP_CONTACT, values, { withCredentials: process.env.REACT_APP_CREDENTIALS })
          .then((res) => {
            let data = res.data;
            setLoading(false);
            let newContact = {
              Email_Id: data?.Email_Id,
              Created_Time: data?.Created_Time,
              contactName: `${data?.First_Name} ${data?.Last_Name}`,
              Created_User_Name: data?.Created_User_Name,
            }
            dispatch(toggleLoader(false));
            contactDetail.handleReset();
            setToggleNewContact(false);
            let contact = [...contacts];
            contact.unshift(newContact);
            setContacts(contact);
            setTotalRecord(totalRecord + 1);
            toast.success('Success');
          })
          .catch((err) => {
            dispatch(toggleLoader(false));
            setToggleNewContact(false);
            if (err?.response?.data?.error?.status === 600) {
              contactDetail.handleReset();
              setLoading(false);
              setLimit(true);
            } else {
              toast.error(err.message);
            }
          })
      }
    }
  });

  const handleDeleteContact = (_id, resetId = () => { }, pageSize, cursor) => {
    dispatch(toggleLoader(false));
    const id = _id ? [_id] : checkedId;
    let data =JSON.stringify({
        org_id: document?.OrgId,
        ids: id,
        limit: pageSize,
        cursor: cursor,
      });

    const headers = {
      'Content-Type': 'application/json',
    };
    axios.delete(process.env.REACT_APP_CONTACT, { headers: headers, withCredentials: process.env.REACT_APP_CREDENTIALS, data: data })
      .then((res) => {
        let data = res.data;
        if (data.contacts_list?.length) {
          setContacts(
            res.data.contacts_list.map((item) => {
              const { First_Name, Last_Name, ...restData } = item;
              return {
                ...restData,
                Created_Time: item.Created_Time,
                contactName: `${First_Name} ${Last_Name}`,
                First_Name,
                Last_Name,
              };
            })
          );
        } else {
          fetchContact(cursor - 1, pageSize, null, 'delete');
          setPageIndex(true);
        }
        setTotalRecord(totalRecord - id?.length);
        resetId();
        setShow(false);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      })
      .finally(() => dispatch(toggleLoader(false)));
    toast.success('Success');
  };

  const handleClick = (id) => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_CONTACT, {
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: { org_id: document?.OrgId, limit: 1, contact_id: id?.Id }
    })
      .then((res) => {
        let data = res.data.contacts_list[0];
        setContactValues(data);
        setShow(true);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      })
      .finally(() => dispatch(toggleLoader(false)));
  };

  const handleModalClose = () => {
    contactDetail.handleReset();
    setToggleNewContact(false);
  };

  return (
    <>
      {pageShow ? (
        <Box className={styles.main_wrap}>
          {show ? (
            <DetailedContact
              values={contactValues}
              setValues={setContactValues}
              setShow={() => fetchContact()}
              onDelete={handleDeleteContact}
            />
          ) : (
            <Box>
              <AppHeader
                btnText='New Contact'
                moduleText='Contacts'
                onBtnClick={() => setToggleNewContact(true)}
                rolePermissions={show_integeration?.user_permission[5]}
                checkAdmin={admin}
              />

              <CustomTable
                getPageIndexSize={({ pageIdx, pageSize }) => {
                  fetchContact(pageIdx, pageSize);
                }}
                pageDeleteIndex={pageIndex}
                pageSetIndex={(val) => setPageIndex(val)}
                totalRecordCount={totalRecord}
                searchName='Search Contacts'
                keys={keys}
                head={tHead}
                body={contacts}
                onDelete={handleDeleteContact}
                getCheckedId={setCheckedId}
                onSearch={({ pageIdx, pageSize, value }) => handleSearch(0, pageSize, value)}
                onRowClick={(val) => {
                  handleClick(val);
                }}
                rolePermissions={show_integeration?.user_permission[5]}
                checkAdmin={admin}
              />
              <ContactTextField
                editContact={false}
                loading={loading}
                toggleNewContact={toggleNewContact}
                handleModalClose={handleModalClose}
                contactDetail={contactDetail}
              />
            </Box>
          )}
        </Box>) : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 80px)'} />}
      <LimitModal limit={limit} handleModalClose={() => setLimit(false)} />
    </>
  );
};

export default Contacts;
