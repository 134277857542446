import { Button, Typography, Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getChannelConnect, getChannelFiles } from "../../Api/UploadChannel";
import AppHeader from "../../component/appHeader/AppHeader";
import { integration_action } from "../../store/integrations/integrationAction";
import { toggleLoader } from "../../store/loader/loaderReducer";
import { Navigation } from "../../utils/handleNavigation";
import './Integrations.css';

const initialState = [
    { name: 'Google Drive', channel: 1, describe: 'Upload documents directly from Google drive to here.', img: 'Integrations/google-drive.png' },
    { name: 'Dropbox', channel: 3, describe: 'Upload documents directly from Dropbox to here.', img: 'Integrations/dropbox.png' },
    { name: 'Box', channel: 4, describe: 'Upload documents directly from Box to here.', img: 'Integrations/box.png' },
    { name: 'Monday', describe: 'Create documents directly from Monday to here.', img: 'Integrations/monday.png' },
    { name: 'Pipedrive', channel: 2, describe: 'Upload documents directly from pipedrive to here.', img: 'Integrations/pipedrive.png' }
];

const Integrations = () => {

    const [connected, setConnected] = useState(false);
    const [channel, setChannel] = useState([]);
    const [channelId, setChannelId] = useState({});
    const [disButton, setDisButton] = useState(null);
    const [move, setMove] = useState(null);
    const [channelDetails, setChannelDetails] = useState([]);
    const [is_closed, setIs_closed] = useState(true);
    const [integration, setIntegration] = useState(initialState)

    const dispatch = useDispatch();
    const navPath = Navigation();
    const state = useSelector((state) => state.document.value);
    const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
    const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');

    const { integration_information, is_connected } = useSelector((state) => state.integrationDetails.value);

    useEffect(() => {
        if ((is_connected && integration_information?.length) || is_closed) handleCheckConnected();
    }, [integration_information, is_connected]);

    useEffect(() => {
        const show_features = show_integeration?.show_features?.length ? JSON.parse(show_integeration?.show_features)[0]?.features : [];
        // if (!show_features?.GOOGLE_DRIVE && show_features?.hasOwnProperty('GOOGLE_DRIVE')) {
        //     setIntegration(current =>
        //         current.filter(item => {
        //             return item.name !== 'Google Drive';
        //         }),
        //     );
        // };

        // if (!show_features?.CRM_MONDAY && show_features?.hasOwnProperty('CRM_MONDAY')) {
        //     setIntegration(current =>
        //         current.filter(item => {
        //             return item.name !== 'Monday';
        //         }),
        //     );
        // };

        if (!show_features?.CRM_PIPEDRIVE && show_features?.hasOwnProperty('CRM_PIPEDRIVE')) {
            setIntegration(current =>
                current.filter(item => {
                    return item.name !== 'Pipedrive';
                }),
            );
        };
    }, []);

    const handleCheckConnected = () => {
        let arr = [];
        let obj = {};
        let channel_id = {};
        integration_information?.map((list, i) => {
            if (arr?.indexOf(list.Channel_Type) === -1) {
                obj = { ...obj, [list.Channel_Type]: true };
                channel_id = { ...channel_id, [list.Channel_Type]: list.Channel_Id };
                arr.push(list.Channel_Type);
            }
        });
        setChannel(arr);
        setConnected(obj);
        setChannelId(channel_id);
        setChannelDetails(integration_information);
    };

    const handleConnect = (type, re_connect) => {
        if (type === 2) window.open('https://oauth.pipedrive.com/oauth/authorize?client_id=05cf2acdf8519413&redirect_uri=ht[…]3A%2F%2Freactsign.centilio.com%2Fcrm%2Fcallback%3Fstate%3D2', '_blank');
        else {
            dispatch(toggleLoader(true));
            setChannel(pre => [...pre, type]);
            let options = { org_id: state?.OrgId, channel_type: type };
            getChannelConnect(options, (res) => {
                dispatch(toggleLoader(false));
                let data = res.data['response data'];
                const childWin = window.open(data, "_blank");
                let userAgent = navigator.userAgent;
                let winClosed = setInterval(function () {
                    if (childWin.closed) {
                        clearInterval(winClosed);
                        handleChannelType(options, type, re_connect);
                        setIs_closed(false);
                    }
                    else if (childWin.length === 0 && !(userAgent.indexOf("Firefox") !== -1)) {
                        if (childWin.location.href.includes('callback')) {
                            childWin.close()
                        }
                    } else if (childWin.length === 0 && userAgent.indexOf("Firefox") !== -1) {
                    }
                }, 250);
            }, (err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
        }
    };

    const handleChannelType = (options, type, re_connect) => {
        dispatch(toggleLoader(true));
        getChannelFiles(options, (res) => {
            let data = res.data;
            if (data.client_access_token) {
                let list = { Channel_Type: type, Channel_Id: data.channel_id };
                let disconnect = channelDetails?.find((s, i) => s.Channel_Id === data.channel_id);
                if (disconnect) {
                    const _channelDetails = channelDetails?.map((item, i) => {
                        if (item?.Channel_Id === data.channel_id) {
                            return { ...item, Status: 1 }
                        }
                        return item;
                    });
                    setChannelDetails(_channelDetails);
                } else {
                    setChannelDetails([...channelDetails, list]);
                };

                if (re_connect === 'reconnect') {
                    const reConnect = channelDetails?.filter((list, i) => list?.Channel_Type !== type);
                    setChannelDetails([...reConnect, list]);
                    dispatch(integration_action({ integration_information: [...reConnect, list] }));
                } else {
                    if (channelDetails?.length) {
                        let obj = [...channelDetails];
                        obj.push(list);
                        dispatch(integration_action({ integration_information: obj }));
                    } else {
                        dispatch(integration_action({ integration_information: [list] }));
                    }
                }
                setChannelId({ ...channelId, [type]: data.channel_id });
                setConnected({ ...connected, [type]: data.client_access_token });
            }
            dispatch(toggleLoader(false));
        }, (err) => {
            toast.error(err.message);
            dispatch(toggleLoader(false));
        })
    };

    const handleDisconnect = (type) => {
        dispatch(toggleLoader(true));
        let data = { org_id: state?.OrgId, channel_type: type, channel_id: channelId[type] };
        axios.delete(process.env.REACT_APP_DROPBOX, { withCredentials: process.env.REACT_APP_CREDENTIALS, data: data })
            .then((res) => {
                let obj = channel?.indexOf(type);
                let data = channel?.filter((k, i) => i !== obj);
                let details = channelDetails?.filter((s, idx) => s?.Channel_Type !== type);
                dispatch(integration_action({ integration_information: details ?? null }));
                setChannel(data);
                setConnected({ ...connected, [type]: false });
                setChannelId({ ...channelId, })
            }).catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            }).finally(() => {
                dispatch(toggleLoader(false));
            });
    };

    const handleInstall = (name) => {
        if (name === 'Monday') {
            window.open(process.env.REACT_APP_INSTALL_MONDAY, '_blank');
        }
    };

    let expired = {};
    return (
        <Box className="integration-container">
            <AppHeader
                btnText='Upload'
                moduleText='Connected Apps'
                onBtnClick={() => navPath('/upload')}
                rolePermissions={true}
                checkAdmin={admin}
            />
            <Box className='integration_wrapper'>
                <Box className='integration_box_body'>
                    {integration?.map((item, index) => {
                        let channel_connect = connected[item?.channel] && channel?.find((channel) => channel === item?.channel);
                        channelDetails?.map((s, i) => channel_connect && s.Status === 2 ? expired[s.Channel_Type] = s.Channel_Type : null);
                        return (
                            <Box key={index} className='integration_body' onMouseOver={() => setDisButton(index)} onMouseOut={() => [setDisButton(null), setMove(null)]}>
                                <Box sx={{ '&:hover': { opacity: channel_connect && disButton === index ? '0.4' : '1.0' }, padding: '20px !important', position: 'relative', opacity: move === index ? '0.4' : '1.0' }}>
                                    <Box className='integration_box'>
                                        <img
                                            src={process.env.REACT_APP_IMAGE_URL + item?.img}
                                            className='dropbox-img'
                                            alt='dropbox'
                                        />
                                        {item?.name === 'Monday' ?
                                            <Button variant="outlined" className="box-connect" onClick={() => handleInstall(item?.name)}>Install</Button> :
                                            expired[item?.channel] && channel_connect ? <Button variant="outlined" className='expired_button'>Expired</Button> :
                                                <Button variant="outlined" style={{ cursor: channel_connect ? 'not-allowed' : 'pointer' }} className={channel_connect ? 'box-connected' : "box-connect"} onClick={() => !channel_connect && handleConnect(item?.channel)}>{channel_connect ? 'Connected' : 'Connect'}</Button>}
                                    </Box>
                                    <Box className="integration_text">
                                        <Typography className="top_text">{item?.name}</Typography>
                                        <Typography className="bottom_text">{item?.describe}</Typography>
                                    </Box>
                                </Box>
                                <Box className='disconnect' onMouseOver={() => setMove(index)} sx={{ opacity: disButton === index && '1 !important', zIndex: '9999999 !important' }}>
                                    {channel_connect &&
                                        <Button variant="outlined" className={expired[item?.channel] ? 'reconnect_button' : 'disconnect_button'} onClick={() => expired[item?.channel] ? handleConnect(item?.channel, 'reconnect') : handleDisconnect(item?.channel)}>{expired[item?.channel] ? 'Reconnect' : 'Disconnect'}</Button>
                                    }
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
};

export default Integrations;