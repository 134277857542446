export const FreeList = ["3 envelopes per month", "Reusable templates"];
export const ProfessionalList = [
  "Shared templates",
  "Collaborative commenting",
  "Customised branding",
];

export const EnterpriseList = [
  "Signer attachments",
  "Formulas and advanced fields",
  "PowerForms",
  "Bulk send",
  "Payments",
];

export const Free = {
  monthly: { planName: "Free", planType: "monthly", price: 0 },
  annually: { planName: "Free", planType: "annually", price: 0 },
};

export const Professional = {
  monthly: { planName: "Professional", planType: "monthly", price: 10 },
  annually: { planName: "Professional", planType: "annually", price: 50 },
};

export const Enterprise = {
  monthly: { planName: "Enterprise", planType: "monthly", price: 30 },
  annually: { planName: "Enterprise", planType: "annually", price: 100 },
};
