import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, Modal } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppHeader from './appHeader/AppHeader';
import styles from './SignDetails.module.css';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { getPDFDownload } from '../Api/PDFDownloadApi';
import { toast } from 'react-toastify';
import { toggleLoader } from '../store/loader/loaderReducer';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteModal } from './DeleteModal';
import axios from 'axios';
import DocumentViewModal from './DocumentViewModal';

const os_type = ['Mac On', 'Linux On', 'Windows On', 'Android On', 'IOS On'];
const browser_type = ['Chrome', 'Firefox', 'Edge', 'Opera', 'Safari'];

const SignDetails = ({
  values,
  setValues,
  signStatus,
  onDelete,
  setShow,
  keys,
  type
}) => {
  const id = setValues.Id;
  const statusId = setValues['Status'];

  const [toggleSignJourney, setToggleSignJourney] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');

  const dispatch = useDispatch();

  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');

  const handleDownLoad = () => {
    let data = { sign_id: setValues?.Id, Org_Id: setValues?.Org_Id, type: type, Template_Name: setValues?.Template_Name }
    setLoading(true);
    dispatch(toggleLoader(true));
    getPDFDownload(data, (res) => {
      let data = new Blob([res?.data], { type: "application/pdf" });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = setValues?.Template_Name?.split('.')[0] + '.pdf';
      link.click();
      setLoading(true);
      dispatch(toggleLoader(false));
    }, (err) => {
      toast.error(err.message);
      setLoading(true);
      dispatch(toggleLoader(false));
    });
  };

  const handleView = (file, org) => {
    dispatch(toggleLoader(true));
    let data = { sign_id: file, Org_Id: org, type: type }
    getPDFDownload(data, (res) => {
      setFile(res.data);
      dispatch(toggleLoader(false));
    }, (e) => {
      toast.error(e.message);
      dispatch(toggleLoader(false));
    });
  };

  const DateFormat = (unix) => {
    let getDate = moment(new Date(unix));
    let startOfToday = moment().startOf('day');
    let startOfDate = moment(getDate).startOf('day');
    let daysDiff = startOfDate.diff(startOfToday, 'days');
    let days = {
      0: 'Today',
      '-1': 'Yesterday',
      1: 'Tomorrow',
    };

    if (Math.abs(daysDiff) <= 1) {
      let day = days[daysDiff];
      let time = moment(new Date(unix)).format('LT');
      return day + ' ' + time;
    } else {
      return moment(new Date(unix)).format('DD/MM/YYYY LT');
    }
  };

  const SignDetails = (key) => {
    switch (key.value) {
      case 'Sign_Journey':
        return setValues[key.value] ? journeyEl : '';
      case 'request_type':
        return 'Direct';
      case 'Status':
        const statusEl = (
          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Box
              className={styles.custom_dot}
              style={{ backgroundColor: signStatus[statusId]?.color }}
            ></Box>
            {signStatus[statusId]?.status}
          </Box>
        );
        return statusEl;
      default:
        return setValues[key.value] ? setValues[key.value] : '-';
    }
  };

  const journeyEl = (

    <Box
      onClick={() => setToggleSignJourney(true)}
      style={{
        color: 'var(--primary)',
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
    >
      Click to View
    </Box>
  );

  let recipients_length = setValues?.Sign_Journey ? Object.keys(JSON.parse(setValues?.Sign_Journey))?.length : null;
  return (
    <Box className={styles.main_wrap}>
      <AppHeader moduleText='Detailed Sign Report' />

      <Box className={styles.edit}>
        <Button
          onClick={() => setShow(false)}
          variant='outlined'
          className={styles.back_button}
          style={{ textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
        >
          <ArrowBackIcon
            style={{ width: '15px', height: '15px', marginRight: '5px' }}
          />
          Back
        </Button>

        <Button
          variant='outlined'
          style={{ borderColor: 'var(--primary)', textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
          onClick={() => handleView(id, setValues?.Org_Id)}
        >
          View
        </Button>

        <Button
          sx={{
            '&:hover': {
              '& .custom_svg_color': {
                filter:
                  'invert(95%) sepia(5%) saturate(0%) hue-rotate(341deg) brightness(105%) contrast(104%) !important',
              },
            },
          }}
          variant='outlined'
          style={{ color: '#23ab94', borderColor: '#23ab94', textTransform: 'unset', fontFamily: 'var(--primary-font-family)', cursor: loading && 'not-allowed' }}
          onClick={() => { !loading && handleDownLoad() }}
        >
          Download
        </Button>

        {(admin || show_integeration?.user_permission[3]?.delete === 1) &&
          <Button
            variant='outlined'
            style={{ color: '#ed4c78', borderColor: '#ed4c78', textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
            startIcon={<DeleteIcon fontSize={'20px'} className={styles.DeleteIcon} color='#ed4c78' />}
            onClick={() => setShowModal(true)}
          >
            Delete
          </Button>}
        <DeleteModal
          showModal={showModal}
          closeModal={(val) => setShowModal(val)}
          handleDelete={() => onDelete(id, undefined, 10, 1)}
        />
      </Box>
      <Box className={styles.main_contact_wrap}>
        <Box className={styles.main_contact}>
          <Box
            style={{
              color: 'var(--table-header-font)',
              gap: '20px',
              fontWeight: 600,
              padding: '15px',
              borderBottom: '1px solid var(--border-color)',
              fontSize: '14px',
              backgroundColor: '#f8fafd',
              borderRadius: '10px 10px 0px 0px',
            }}
          >
            Detailed Information
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: ' 15px 20px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                fontWeight: 500,
                color: '#193037',
                fontFamily: 'var(--primary-font-family)',
              }}
            >
              <Box className={styles.name}>{values?.Template_Name}</Box>
            </Box>

            <Box style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              {values?.map((val, idx) => {
                const mapKey = keys[idx];
                let date = '';
                if (mapKey === 'Created_Time' || mapKey === 'Modified_Time') {
                  date = DateFormat(setValues[mapKey]);
                }

                return (
                  val === 'Sign Journey' && !JSON.parse(show_integeration?.plans_info)[0]?.plan_flags?.SIGN_JOURNEY ? null :
                    <Box key={val} style={{ display: 'flex' }}>
                      <Box
                        style={{
                          minWidth: '200px',
                          fontWeight: 500,
                          fontSize: '14px',
                          color: 'var(--table-header-font)',
                          fontFamily: 'var(--primary-font-family)'
                        }}
                      >
                        {val}
                      </Box>
                      <Box style={{ fontWeight: 400, fontSize: '14px', color: 'var(--table-font-color)' }}>
                        {mapKey === 'Created_Time' || mapKey === 'Modified_Time' ? (
                          date
                        ) : (
                          <SignDetails value={mapKey} />
                        )}
                      </Box>
                    </Box>
                );
              })}
            </Box>
          </Box>
          {toggleSignJourney && <Modal
            open={toggleSignJourney}
            sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
            disableAutoFocus={true}
            onClose={() => setToggleSignJourney(false)}
            closeAfterTransition
          >
            <Box className={styles.new_contact_wrap} style={{ height: recipients_length > 2 ? 'calc(100vh - 100px)' : null }}>
              <IconButton
                className={styles.close}
                onClick={() => setToggleSignJourney(false)}
              >
                <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
              </IconButton>
              <Box style={{ fontSize: '20px', fontWeight: '600', color: 'var(--header-color)' }}>Sign Journey</Box>
              <>
                <Box sx={{ overflow: recipients_length > 2 ? 'scroll' : 'hidden', height: recipients_length > 2 ? 'calc(100vh - 200px)' : '100%' }} className={styles.recipient_body}>
                  {Object.keys(JSON.parse(setValues?.Sign_Journey))?.map((list, idx) => (
                    <Box key={idx}>
                      <Box style={{ padding: '25px 15px 0px 0px', fontSize: '15px', fontWeight: 400, color: 'var(--table-font-color)' }}>
                        {list}
                      </Box>
                      {JSON.parse(JSON.parse(setValues?.Sign_Journey)[list])?.journey?.map((item, i) => {
                        const showLine = JSON.parse(JSON.parse(setValues?.Sign_Journey)[list])?.journey?.length !== i + 1
                        return (
                          <Box style={{ position: 'relative' }} key={i}>
                            <CustomSteper showLine={showLine} />
                            <Box className={styles.top_text}>
                              <Typography variant="caption" style={{ color: signStatus[item?.status]?.color, fontSize: 13, fontFamily: 'var(--primary-font-family)' }}>{signStatus[item?.status]?.status}</Typography>
                              <Typography variant="caption" style={{ float: 'right', fontSize: 12, fontFamily: 'var(--primary-font-family)', color: 'var(--table-font-color)' }}>{moment(new Date(item?.created_time)).format('DD/MM/YYYY LT')}</Typography>
                            </Box>
                            <Box className={styles.bottom_text}>
                              <Typography style={{ fontSize: 13, fontFamily: 'var(--primary-font-family)', color: 'var(--table-font-color)' }} variant="caption">{os_type[item?.os - 1] + ' ' + browser_type[item?.browser - 1]}</Typography>
                            </Box>
                          </Box>
                        )
                      }
                      )}
                    </Box>
                  ))
                  }
                </Box>
              </>
            </Box>
          </Modal>}
        </Box>
      </Box>
      <DocumentViewModal file={file} setFile={(value) => setFile(value)} />
    </Box>
  );
};

export default SignDetails;


const CustomSteper = ({ showLine }) => {
  return (
    <Box style={{ height: "100%", position: 'absolute', width: 10 }}>
      {showLine &&
        <Box style={{ height: 'calc(100% + 20px)', borderLeft: '1px dashed var(--primary)', position: 'absolute', translate: '-50%', left: '50%  ', top: 6 }}></Box>}
      <Box style={{ height: 10, width: 10, borderRadius: '50%', backgroundColor: 'var(--primary)', position: 'absolute', top: 6 }}></Box>
    </Box>
  )
}