import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tableinfo: '',
    values: "",
    tabledata: "",
};


export const adminAccountDetails = createSlice({
    name: "admin_account_details",
    initialState,
    reducers: {
        adminAccountDetail: (state, action) => {
            state.tableinfo = action?.payload?.data?.plans_info[1].plan_details
            state.values = action?.payload?.data?.plans_info[0]
            state.tabledata = action?.payload?.data

        },
    },
});
export const { adminAccountDetail } = adminAccountDetails.actions;


export default adminAccountDetails.reducer;
