import { Box, Button, Checkbox } from '@mui/material';
import styles from './settings.module.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { toast } from 'react-toastify';
import "cropperjs/dist/cropper.css";
import { signatureId_action } from '../../store/signatureId/signatureIdAction';
import SignatureField from '../../component/SignatureField';
import CheckedIcon from '../../component/checkedIcon';
import Skeleton from '@mui/material/Skeleton';

const initialState = { type: 1, draw: 1, upload: 1 };

const Settings = () => {
  const document = useSelector((state) => state?.document?.value);
  const dispatch = useDispatch();

  const [toggleNewContact, setToggleNewContact] = useState(false);
  const [type, setType] = useState('draw');
  const [color, setColor] = useState('#000');
  const [font, setFont] = useState('Square Peg');
  const [getImage, setGetImage] = useState({});
  const [getText, setGetText] = useState(null);
  const [imgType, setImgType] = useState('');
  const [getRes, setGetRes] = useState(false);
  const [signType, setSignType] = useState(initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSign();
  }, []);

  const dataURLtoBlob = (dataURL) => {
    let arr = dataURL.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let b64 = atob(arr[1]);
    let n = b64.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = b64.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleSubmit = async (value) => {
    let img = type === 'upload' ? imgType : 'sign.jpeg';
    let blob = dataURLtoBlob(value);
    let file = new File([blob], img);

    let form = { file: file, org_id: document?.OrgId };

    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_SIGNSETTING_DRIVE, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        if (res.status === 200) {
          setToggleNewContact(false);
          dispatch(toggleLoader(false));
          dispatch(signatureId_action(res?.data?.response?.Sign_Image));
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
    setToggleNewContact(false);
  };

  const fetchSign = () => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_SIGNSETTING, {
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: { org_id: document?.OrgId },
    })
      .then((res) => {
        if (!res.data) {
          setGetImage(null);
          setGetRes(true);
          dispatch(toggleLoader(false));
        } else {
          let data = res?.data?.settings?.sign_image;
          if (data) {
            dispatch(signatureId_action(res?.data?.settings?.sign_image));
            getSignatureImg(res?.data);
          } else {
            setGetRes(true);
            dispatch(toggleLoader(false));
          }
          let type = JSON?.parse(res?.data?.settings?.signature_options);
          setSignType(type);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      })
  };

  const getSignatureImg = (list) => {
    axios.get(process.env.REACT_APP_SIGNSETTING_DRIVE, {
      responseType: 'blob',
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: { org_id: document?.OrgId, file_id: list?.settings?.sign_image },
    })
      .then((res) => {
        let imgUrl = URL.createObjectURL(res.data);
        setGetRes(true);
        setGetImage(imgUrl);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        setGetRes(true);
        dispatch(toggleLoader(false));
      })
      .finally(() => dispatch(toggleLoader(false)));
  };

  const handleSignCheckBox = (e, key) => {
    const val = e.target.checked ? 1 : 0;
    setSignType({ ...signType, [key]: val })
    setLoading(false);
  };

  const handleSignType = () => {
    dispatch(toggleLoader(true));
    let options = { org_id: document?.OrgId, signature_options: signType };
    axios.put(process.env.REACT_APP_SIGNSETTING, options, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        setLoading(true);
        dispatch(toggleLoader(false));
        toast.success('Success');
      })
      .catch((err) => {
        setLoading(true);
        toast.error(err.message);
        dispatch(toggleLoader(false));
      })
  };

  return (
    <div className={styles.inn_sec}>
      {getRes ?
        <div className={styles.wrapper}>
          <div className={styles.c_header}>
            <div className={styles.title}>
              <div className={styles.header_text}>Sign Settings</div>
            </div>
          </div>
          <span
            id='sign'
            style={{
              position: 'absolute',
              fontSize: '50px',
              top: '-1000%',
              fontFamily: font,
              color: color,
            }}
          >
            {getText}
          </span>

          <div className={styles.scroll_y}>
            <div className={styles.default_sign} id={styles.default_sign}>
              <h3>Sign</h3>
              <div className={styles.b_cont}>
                <div className={styles.wrapper}>
                  <div className={styles.row}>
                    <div className={styles.sign_frame} style={{ position: 'relative' }}>
                      <div className={styles.signature_img}>
                        {getImage && Object.keys(getImage).length ? (
                          <img
                            width='100%'
                            height='100%'
                            style={{ position: 'absolute', objectFit: 'scale-down' }}
                            src={getImage}
                            alt='signature'
                          />
                        ) : null}
                      </div>
                    </div>
                    <Button onClick={() => setToggleNewContact(true)} className={styles.btn + ' ' + styles.sign_btn} id={styles.change_sign} variant="contained">
                      {getImage && Object.keys(getImage).length ? 'Change Signature' : 'Add Signature'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box className={styles.scroll_y}>
            <Box className={styles.default_sign} id={styles.default_sign}>
              <h3>Sign Setting Type</h3>
              <Box className={styles.b_cont} style={{ display: 'flex' }}>
                <Box className={styles.sign_wrapper} >
                  <Box className={styles.signature_checkbox}>
                    <Checkbox
                      sx={{ transform: 'scale(0.8)', borderColor: 'var(--border-color)', minHeight: 45, minWidth: 45 }}
                      checkedIcon={<CheckedIcon style={{ margin: 2 }} />}
                      checked={!!signType?.draw}
                      onClick={(e) => handleSignCheckBox(e, 'draw')}
                    />
                    <Box>Draw</Box>
                  </Box>
                  <Box className={styles.signature_checkbox}>
                    <Checkbox
                      sx={{ transform: 'scale(0.8)', borderColor: 'var(--border-color)', minHeight: 45, minWidth: 45 }}
                      checkedIcon={<CheckedIcon style={{ margin: 2 }} />}
                      checked={!!signType?.type}
                      onClick={(e) => handleSignCheckBox(e, 'type')}
                    />
                    <Box>Type</Box>
                  </Box>
                  <Box className={styles.signature_checkbox}>
                    <Checkbox
                      sx={{ transform: 'scale(0.8)', borderColor: 'var(--border-color)', minHeight: 45, minWidth: 45 }}
                      checkedIcon={<CheckedIcon style={{ margin: 2 }} />}
                      checked={!!signType?.upload}
                      onClick={(e) => handleSignCheckBox(e, 'upload')}
                    />
                    <Box>Upload</Box>
                  </Box>
                </Box>
                <Box className={styles.button_container}>
                  <Button onClick={() => { !loading && handleSignType() }} sx={{ cursor: loading && 'not-allowed' }} className={styles.sign_save_button} variant="contained">
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </div> : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 80px)'} />
      }
      <SignatureField
        toggleNewContact={toggleNewContact}
        getText={(e) => setGetText(e)}
        getGetImage={(e) => setGetImage(e)}
        getToggleNewContact={(value) => setToggleNewContact(value)}
        handleSubmit={handleSubmit}
        getType={(type) => setType(type)}
        getColor={(color) => setColor(color)}
        getFont={(font) => setFont(font)}
        getImageType={(imageType) => setImgType(imageType)}
      />
    </div>
  );
};

export default Settings;