const routes = [{
  component: "Home",
  path: "home",
  exact: true,
},
{
  component: "WelcomePage",
  path: "myDocuments",
  exact: true,
},
{
  component: "Upload",
  path: "upload",
  exact: true,
},
{
  component: "SignSelf",
  path: "signMyself",
  exact: true,
},
{
  component: "SendSign",
  path: "sendSign",
  exact: true,
},
{
  component: "Templates",
  path: "templates",
  exact: true,
},
{
  component: "PDFViewer",
  path: "document/:id",
  exact: true,
},
{
  component: "Settings",
  path: "settings",
  exact: true,
},
{
  component: "Contacts",
  path: "contacts",
  exact: true,
},
{
  component: "AddRecipient",
  path: "template/:id/add_recipient",
  exact: true,
},
{
  component: "PDFViewer",
  path: "editTemplate/:id",
  exact: true,
},
{
  component: "PDFViewer",
  path: "editAsNewTemplate/:id",
  exact: true,
},
{
  component: "SignDetails",
  path: "sendforsign/:id",
  exact: true,
},
{
  component: "SignDetails",
  path: "sendformyself/:id",
  exact: true,
},
{
  component: "Integrations",
  path: "integrations",
  exact: true,
},
{
  component: "PriceList",
  path: "priceList",
  exact: true,
},
{
  component: "Payment",
  path: "payment",
  exact: true,
},
{
  component: "CreateTemplates",
  path: "CreateTemplates",
  exact: true,
},
{
  component: 'MondayCRM',
  path: 'sdkhome',
  exact: true
},
{
  component: 'RecentActivity',
  path: 'activity',
  exact: true
}];

export default routes;