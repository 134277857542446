import axios from "axios";

export const getadminaccountdata = (resp, error) => {
    axios.get(process.env.REACT_APP_USER_HEAD, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};

export const getadmindata = (resp, error) => {
    axios.get(process.env.REACT_APP_USER_TABLE_NAME, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};