import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import NavBar from "./component/navBar/navBar";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import TopBar from './component/topBar/TopBar';
import { useEffect, useState } from 'react';
import * as Pages from './pages/index';
import routes from './router/routes';
import { crm_param_action } from './store/crmParam/crmParamAction';
import { useDispatch } from 'react-redux';
import { document_action } from './store/documentDetails/document_action';
import { signId_action } from './store/signIDDetails/signId_action';
import { useIsMount } from "./utils/commonFunction";

const AppRoute = () => {
    const isLoggedIn = true;
    const location = useLocation();
    const isMount = useIsMount();
    const dispatch = useDispatch();

    const [toggle, setToggle] = useState(null);
    const [prePath, setPrePath] = useState('/');
    const [isInitFetched, setIsInitFetched] = useState(false);
    const [isFetchedSdkhome, setIsFetchedSdkhome] = useState(false);
    const [url_path, setUrl_path] = useState(null);
    const [documentId, setDocumentId] = useState(null);

    const loader = useSelector((state) => state?.loader);
    const signId_details = useSelector((state) => state?.signId_details?.value);
    const crm_param = useSelector((state) => state.crm_param.value);
    const digestDetails = useSelector((state) => state?.digestDetails?.value);

    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);
    const navigationEntries = window.performance.getEntriesByType('navigation');
    const pathName = location.pathname.split('/').find(v => v === 'editAsNewTemplate');
    const crm_params = pathName ? window?.location?.href?.split('&')[2]?.split('=')[1] : window?.location?.href?.split('=')[1];
    const draftType = window?.location?.href?.split('&')[2]?.split('=')?.shift();
    const crm_param_url = draftType ? draftType : window?.location?.href?.split('?')[1]?.split('=')?.shift();
    const sdk_document_id = location.pathname?.split('/')[3];
    const sdk_org_id = location.pathname?.split('/')[1]?.split('c')[1];

    let enabled_init = ['home', 'myDocuments', 'upload', 'signMyself', 'sendSign', 'templates', 'settings', 'contacts', 'integrations', 'activity', 'document', 'editAsNewTemplate', 'editTemplate', 'priceList', 'payment'];
    let list = ['document', 'editAsNewTemplate', 'editTemplate', 'signrequest', 'sdkhome', 'mydocument', 'uploaddocument', 'bookdemo', 'invitation', 'centiliodocs', 'howtouse', 'pdfconverter', 'priceList', 'payment'];
    const init_disable = ['signrequest', 'bookdemo', 'centiliodocs', 'admintool', 'howtouse', 'blog', 'pdfconverter', 'worngpage'];
    const disableTopNav = location.pathname?.split('/')?.find((v) => list.indexOf(v) !== -1);
    const sdkPath = location.pathname?.split('/')[1];
    const navigate = useNavigate();

    useEffect(() => {
        if (crm_param_url === 'crmparam' || sdkPath === 'sdkhome') {
            if (pathName === 'editAsNewTemplate') {
                navigate(window.location.hash.split('&crmparam')[0].split('#')[1]);
                let crm_template = { Template_Id: sdk_document_id, Org_Id: sdk_org_id, crm_param: 'crm_draft' };
                dispatch(signId_action(crm_template));
            } else {
                dispatch(crm_param_action(crm_params));
                dispatch(document_action(null));
            }
        } else {
            dispatch(crm_param_action(null));
        }
    }, []);

    useEffect(() => {
        if (isMount || location?.pathname === '/') return
        setUrl_path(window?.location?.href?.split('/')[4]);
        let nav_refresh = window?.location?.href;
        if (nav_refresh?.split('/')[5] === 'document') setDocumentId(nav_refresh?.split('/')[6])

        let dis_refresh = nav_refresh?.split('/')?.length >= 6 && nav_refresh?.split('/')[4]?.substring(0, 1);
        if ((nav_refresh?.split('/')[4] !== url_path || (nav_refresh?.split('/')[5] === 'document' && nav_refresh?.split('/')[6] !== documentId)) && reload[0] !== 'reload' && sdkPath !== 'sdkhome') {
            if (nav_refresh?.split('#')[1]?.split('/')[1] !== 'error-404' && dis_refresh && !location?.state?.switch_org_reload) {
                window.location.reload(true);
            }
        };

        if ((nav_refresh?.split('#')[1]?.split('/').length <= 2 || (nav_refresh?.split('#')[1]?.split('/').length === 3 && nav_refresh?.split('#')[1]?.split('/')[2] === '')) && nav_refresh?.split('#')[1]?.split('/')[1] !== 'error-404') {
            if (init_disable.indexOf(location.pathname?.split('/')?.find(s => s)) === -1 && sdkPath !== 'sdkhome') navigate('/');
        };
    }, [navigationEntries]);

    useEffect(() => {
        if (sdkPath === 'sdkhome' && !isFetchedSdkhome) {
            setIsFetchedSdkhome(true);
        } else if (crm_param_url === 'crmparam' || signId_details?.crm_param) {
            setIsInitFetched(true);
        }
    }, [navigationEntries]);

    useEffect(() => {
        if (reload[0] === 'reload') {
            if (init_disable.indexOf(location.pathname?.split('/')?.find(s => s)) !== -1) {
                if (crm_params && crm_param_url !== 'digest') {
                    navigate(`/${location.pathname?.split('/')?.find(s => s)}?crmparam=${crm_param}`);
                    dispatch(crm_param_action(crm_params));
                } else if (crm_param_url === 'digest') {
                    navigate(`/${location.pathname?.split('/')?.find(s => s)}?digest=${digestDetails}`);
                } else {
                    navigate(`/${location.pathname?.split('/')?.find(s => s)}`);
                }
            } else {
                setPrePath(location.pathname);
                navigate('/');
            };

            if (crm_param || signId_details?.crm_param) {
                navigate(navigationEntries[0]?.name?.split('#')[1]);
            };
        }
    }, []);

    useEffect(() => {
        if (!isInitFetched && enabled_init?.indexOf(location?.pathname?.split('/')[2]) !== -1) {
            setPrePath(location.pathname);
            navigate('/');
        }
    }, []);

    return (
        <>
            {loader.isLoading && (
                <Box sx={{ width: "100%", height: list.indexOf(disableTopNav) !== -1 ? "2px" : "0px", top: 1, zIndex: 4 }}>
                    <LinearProgress value={0} valueBuffer={10} />
                </Box>
            )}
            {isLoggedIn ? (
                <div className='main_wrap'>
                    {list.indexOf(disableTopNav) === -1 && <NavBar isFetched={isInitFetched} expand={toggle} />}
                    <div style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
                        {list.indexOf(disableTopNav) === -1 && <TopBar isFetched={isInitFetched} expand={toggle} setIsNavExpand={setToggle} />}
                        <div className='main_content'>
                            <Routes>
                                <Route path="/*" element={<Navigate to='/error-404' />} />
                                <Route path="/error-404" element={<Pages.ErrorPage />} />
                                <Route path="/" exact element={<Pages.OrgInit prePath={prePath} isFetchedSdkhome={(val) => setIsFetchedSdkhome(val)} isFetched={(fetched) => setIsInitFetched(fetched)} />} />
                                <Route path=":id">
                                    {routes.map((route, i) => {
                                        const { path, component, exact } = route;
                                        const Page = Pages[component];

                                        return (
                                            <Route key={i} exact={exact} path={path} element={isInitFetched ? <Page /> : null} />
                                        )
                                    })}
                                </Route>
                                <Route exact path='/signrequest' element={<Pages.SignDocument />} />
                                <Route exact path='/sdkhome' element={isFetchedSdkhome ? <Pages.MondayCRM /> : null} />
                                <Route path='/sdk'>
                                    <Route exact path='mydocument' element={<Pages.SendSign />} />
                                    <Route exact path='uploaddocument' element={<Pages.Upload />} />
                                </Route>
                                <Route
                                    exact
                                    path="/CreateTemplates"
                                    element={<Pages.CreateTemplates />}
                                />
                                <Route exact path='/bookdemo' element={<Pages.EventSchedule />} />
                                <Route exact path='/invitation' element={<Pages.Invitation />} />
                                <Route exact path='/admintool' element={<Pages.AdminTool />} />
                                <Route exact path='/centiliodocs' element={<Pages.CentilioDoc />} />
                                <Route exact path='/howtouse' element={<Pages.HowToUse />} />
                                <Route exact path='/blog' element={<Pages.Blog />} />
                                <Route exact path='/pdfconverter' element={<Pages.PdfConverter />} />
                                <Route exact path='/worngpage' element={<Pages.WorngPage />} />
                                <Route exact path='/emailcampaign' element={<Pages.EmailCampaign />} />
                            </Routes>
                        </div>
                    </div >
                </div >
            ) : (
                <Routes></Routes>
            )}
        </>
    );
};

export default AppRoute;
