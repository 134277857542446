import React, { useState } from 'react';
import styles from './admin.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {
    Box,
    Button,
    InputAdornment,
    Select,
    TextField,
    Typography,
    MenuItem,
    Modal,
    TextareaAutosize,
    Popover,
    FormGroup,
    FormControlLabel,
    FormControl,
    InputLabel,
    Paper,
    IconButton,
    InputBase,
    Divider,
} from '@mui/material';
import { Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TaskIcon from '@mui/icons-material/Task';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import moment from 'moment';
import CheckedIcon from '../checkedIcon';
import '../../pages/WelcomePage/WelcomePage.css';
import { DeleteModal } from '../DeleteModal';
import { styled, css } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ClearIcon from '@mui/icons-material/Clear';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
const ModalContent = styled('div')(
    ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);
const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const AdminCustomTable = ({
    editButton,
    head,
    body,
    filterHead,
    onDelete,
    pageIndex,
    getCheckedId,
    onSearch,
    onRowClick,
    keys,
    totalRecordCount,
    getPageIndexSize,
    handleEditTemplate,
    searchName,
    cursors,
    courserData,
    nextpage,
    limitData,
    table,
    emailCampaign,
    allUser,
    getCheckedValue,
    getCheckedHead,
    filterdata,
    SearchDomain

}) => {
    const pageSizeArr = [10, 20, 30, 40, 50, 100, 200];
    const [pageSize, setPageSize] = useState(30);
    const [pageIdx, setPageIdx] = useState(0);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [checkedId, setCheckedId] = useState([]);
    const [templateList, setTemplateList] = useState({});
    const [cursor, setCursor] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [modalValue, setmodalValue] = useState('');
    const [checkedValue, setCheckedValue] = useState({ Body: '', Company_Name: '', Department: '', Industry: '', Website: '', Subject: '', Recipient_Email: '' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [checkedHead, setCheckedHead] = useState([]);
    const [popperName, setPopperName] = useState('');
    const [searchValue, setSearchValue] = useState();
    const statusValue = ['AWAIT', 'APPROVED', 'SENT', 'FAILED', 'LATER', 'SEND_NOW'];
    const [filters, setfilters] = useState({ columns: 'Status', value: "0", operator: "and", condition: "=" })

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    let localValue = JSON.parse(localStorage.getItem("columnHead"))


    useEffect(() => {
        if (emailCampaign)
            if (checkedHead?.length)
                localStorage.setItem('columnHead', JSON.stringify(checkedHead));
    }, [checkedHead]);


    const handleClick = (event, name) => {
        setPopperName(name)
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (localValue?.length < 14) {
            setCheckedHead(localValue);

        }
        else {
            setCheckedHead(filterHead)
        }

    }, [])


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageHandler = (type) => {
        courserData(type)
    };


    useEffect(() => {

        if (body?.length && checkedId?.length === body?.length) {
            setIsAllChecked(true);
        } else {
            setIsAllChecked(false);
        }
    }, [checkedId]);


    const handleCheckAll = (e) => {
        const isChecked = e.target.checked;
        setIsAllChecked(isChecked);
        if (isChecked) {
            setCheckedId(body.map((item) => item.Id));
        } else {
            setCheckedId([]);
        }
    };

    const handleUpdate = (e) => {

        getCheckedId(checkedId, e);
        setCheckedId([]);
        setShowModal(false)
    }

    const handleValueEdit = (e) => {
        getCheckedValue(checkedId, checkedValue, e);
        setCheckedId([]);
        setShowModal(false)
    }

    const handleModal = (value) => {
        setShowModal(true)
        setmodalValue(value)
    }

    const handleUpdateModal = (value, id) => {
        setShowFormModal(true)
        setmodalValue(value)

        if (value === 'Edit') {
            handleEdit(id)
        }
        else {
            handleView(id)
        }

    }

    const handleView = (value) => {

        body.map((val) => {
            if (val.Id === value) {
                setCheckedValue({ ...checkedValue, Id: val.Id, Body: val.Body, Company_Name: val.Company_Name, Department: val.Department, Industry: val.Industry, Website: val.Website, Subject: val.Subject, Recipient_Email: val.Recipient_Email })
            }
        }
        )
    }

    const handleEdit = (value) => {
        body.map((val) => {
            if (val?.Id === value) {
                setCheckedValue({ ...checkedValue, Id: val.Id, Body: val.Body, Company_Name: val.Company_Name, Department: val.Department, Industry: val.Industry, Website: val.Website, Subject: val.Subject, Recipient_Email: val.Recipient_Email })
            }
        }
        )
    }

    const handlefilter = (e, i) => {
        if (checkedHead.includes(undefined && null)) {
            setCheckedHead(prevCheckedHead => prevCheckedHead.filter(function (element) {
                return element !== undefined;
            }))
        }

        else if (checkedHead.includes(e.target.name)) {
            setCheckedHead(prevCheckedHead => prevCheckedHead.filter(value => value !== e.target.name));
        }
        else
            setCheckedHead(prevSelectedHead => {
                const newState = [...prevSelectedHead];
                newState.splice(i, 0, e.target.name);
                return newState;
            });

    }

    const handleSelect = () => {
        if (checkedHead?.length === 14) {
            setCheckedHead([])
        }
        else {
            setCheckedHead(filterHead)
        }
    }

    const submitFile = () => {
        getCheckedHead(checkedHead)
        handleClose()
    }

    const handleCloseModal = () => {
        setShowFormModal(false)
        setCheckedId([]);
    }

    const sendDataToTable = () => {
        filterdata([filters])
        handleClose()
    }

    const handleRemove = () => {
        SearchDomain("");
        setSearchValue("")

    }
    const DateFormat = (unix) => {


        let getDate = moment(new Date(unix));
        let startOfToday = moment().startOf('day');
        let startOfDate = moment(getDate).startOf('day');
        let daysDiff = startOfDate.diff(startOfToday, 'days');
        let days = {
            '0': 'Today',
            '-1': 'Yesterday',
            '1': 'Tomorrow'
        };

        if (Math.abs(daysDiff) <= 1) {
            let day = days[daysDiff];
            let time = moment(new Date(unix)).format('LT')
            return day + ' ' + time;
        } else {
            return moment(new Date(unix)).format('DD/MM/YYYY LT');
        }
    };

    useEffect(() => {
        if (getCheckedHead) {
            getCheckedHead(checkedHead)
        }

    }, [checkedHead])
    return (

        <Box className={styles.table_wrap}>
            {table === 'user' ? <Box ></Box> : <Box className={styles.table_nav}>
                <Box className={styles.nav_item}>
                    {emailCampaign && (
                        <Box>
                            <Button
                                style={{
                                    textTransform: 'unset',
                                    borderColor: ' #377dff',
                                    color: ' #377dff',
                                    pointerEvents: 'auto',
                                    fontFamily: 'var(--primary-font-family)'
                                }}
                                variant='outlined'
                                color='error'
                                startIcon={<ViewColumnIcon fontSize={'10px'} fontcolor={"#ed4c78"} />}
                                onClick={(e) => handleClick(e, "colum")}
                            >
                                Select Column
                            </Button>
                            {popperName === "colum" && (<Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography sx={{ p: 2 }}>
                                    <FormControlLabel
                                        onClick={(e) => handleSelect(e)}
                                        control={<Checkbox />}
                                        label={checkedHead?.length !== 14 ? "Selecte All" : "UnSelecte All"}
                                        checked={checkedHead?.length === 14 ? true : false}
                                    />
                                    {filterHead?.map((item, i) => (
                                        <Box>
                                            <FormGroup>
                                                <FormControlLabel name={item} onClick={(e) => handlefilter(e, i)} control={<Checkbox />} label={item} checked={checkedHead.includes(item)} />
                                            </FormGroup>
                                        </Box>))}
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            component="label"

                                            onClick={() => submitFile()} >
                                            Submit
                                        </Button>
                                    </Box>
                                </Typography>
                            </Popover>)}
                        </Box>
                    )}
                </Box>
                <Box className={styles.nav_item}>

                    {emailCampaign && (
                        <Box className={styles.nav_item}>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "250px ", height: '40px' }}
                                className={styles.input_filed}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Domain"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    {searchValue && (<ClearIcon className={styles.clear_icon} onClick={() => handleRemove()} />)}
                                </IconButton>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon onClick={() => SearchDomain(searchValue)} />
                                </IconButton>


                            </Paper>
                            < Button
                                // disabled={!checkedId.length}
                                style={{
                                    textTransform: 'unset',
                                    borderColor: ' #377dff',
                                    color: ' #377dff',
                                    pointerEvents: 'auto',
                                    fontFamily: 'var(--primary-font-family)'
                                }}
                                variant='outlined'
                                color='error'
                                startIcon={<AssignmentTurnedInIcon fontSize={'10px'} fontcolor={"#ed4c78"} />}
                                onClick={(e) => handleClick(e, "status")}
                            >
                                Status
                            </Button>
                            {popperName === "status" && (<Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography sx={{ p: 2 }}>

                                    <Box sx={{ display: 'flex', gap: "30px" }}>
                                        <FormControl sx={{ width: "150px" }}>
                                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={filters.value}
                                                label="Status"
                                                onChange={(e) => setfilters({ ...filters, value: e.target.value })}
                                            >
                                                {statusValue.map((item, i) => (<MenuItem value={i} key={i} >{item}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        <Box sx={{ paddingTop: "5px" }}>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                onClick={sendDataToTable}
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Box>

                                </Typography>
                            </Popover>)}

                            < Button
                                disabled={!checkedId?.length}
                                style={{
                                    textTransform: 'unset',
                                    cursor: !checkedId?.length ? 'not-allowed' : 'pointer',
                                    borderColor: ' #377dff',
                                    color: ' #377dff',
                                    pointerEvents: 'auto',
                                    fontFamily: 'var(--primary-font-family)'
                                }}
                                variant='outlined'
                                color='error'
                                startIcon={<ScheduleIcon fontSize={'10px'} fontcolor={"#ed4c78"} />}
                                onClick={() => handleUpdate('schedule')}
                            >
                                Send Now
                            </Button>

                            < Button
                                disabled={!checkedId?.length}
                                style={{
                                    textTransform: 'unset',
                                    cursor: !checkedId?.length ? 'not-allowed' : 'pointer',
                                    borderColor: ' #377dff',
                                    color: ' #377dff',
                                    pointerEvents: 'auto',
                                    fontFamily: 'var(--primary-font-family)'
                                }}
                                variant='outlined'
                                color='error'
                                startIcon={<ScheduleSendIcon fontSize={'10px'} fontcolor={"#ed4c78"} />}
                                onClick={() => handleUpdate('later')}
                            >
                                Later
                            </Button>

                            < Button
                                disabled={!checkedId?.length}
                                style={{
                                    textTransform: 'unset',
                                    cursor: !checkedId?.length ? 'not-allowed' : 'pointer',
                                    borderColor: ' #23ab94',
                                    color: ' #23ab94',
                                    pointerEvents: 'auto',
                                    fontFamily: 'var(--primary-font-family)'
                                }}
                                variant='outlined'
                                color='error'
                                startIcon={<TaskIcon fontSize={'10px'} fontcolor={"#ed4c78"} />}
                                onClick={() => handleModal('Aprove')}
                            >
                                Aprove
                            </Button>

                            {/* < Button
                                disabled={checkedId?.length ? checkedId.length <= 1 ? false : true : true}
                                style={{
                                    textTransform: 'unset',
                                    cursor: !checkedId?.length ? 'not-allowed' : 'pointer',
                                    borderColor: ' #ff8102',
                                    color: ' #ff8102',
                                    pointerEvents: 'auto',
                                    fontFamily: 'var(--primary-font-family)'
                                }}
                                variant='outlined'
                                color='error'
                                startIcon={<EditIcon fontSize={'10px'} fontcolor={"#ed4c78"} />}
                                onClick={() => handleUpdateModal('Edit')}
                            >
                                Edit
                            </Button> */}
                            < Button
                                disabled={!checkedId?.length}
                                style={{
                                    textTransform: 'unset',
                                    cursor: !checkedId?.length ? 'not-allowed' : 'pointer',
                                    borderColor: ' #ed4c78',
                                    color: ' #ed4c78',
                                    pointerEvents: 'auto',
                                    fontFamily: 'var(--primary-font-family)'
                                }}
                                variant='outlined'
                                color='error'
                                startIcon={<DeleteIcon fontSize={'10px'} fontcolor={"#ed4c78"} />}
                                onClick={() => handleModal('Delete')}
                            >
                                Delete
                            </Button>

                        </Box>)}

                    <Modal
                        open={showModal}
                        style={{ top: "35%", left: '35%' }}
                    >
                        <ModalContent sx={{ width: 400 }}>
                            <h2 id="unstyled-modal-title" className="modal-title">
                                Are you sure?
                            </h2>
                            <p id="unstyled-modal-description" className="modal-description">
                                {`You want to ${modalValue} this item?`}
                            </p>
                            <Box sx={{
                                display: 'flex', justifyContent: 'flex-end', gap: '10px'
                            }}>
                                <Button variant="outlined" onClick={() => setShowModal(false)}>Cancel</Button>
                                <Button variant="contained" onClick={() => handleUpdate({ modalValue })}>{modalValue}</Button>
                            </Box>
                        </ModalContent>
                    </Modal>
                    <Modal
                        open={showFormModal}
                        style={{ top: "16%", left: '26%' }}
                    >
                        <ModalContent sx={{ width: 800 }}>
                            <h2 id="unstyled-modal-title" className="modal-title">
                                {modalValue}
                            </h2>
                            <Box className={styles.model_popup_form}>
                                <Box className={styles.model_popup_head}>Body </Box>
                                <Textarea className={styles.model_popup_textarea} value={checkedValue.Body} placeholder="Body" onChange={(e) => setCheckedValue({ ...checkedValue, Body: e.target.value })} disabled={modalValue === 'View' ? true : false} />
                            </Box>
                            <Box className={styles.model_popup_form}>
                                <Box className={styles.model_popup_head}>Industry </Box>
                                <Textarea className={styles.model_popup_input} value={checkedValue.Industry} placeholder="Industry" onChange={(e) => setCheckedValue({ ...checkedValue, Industry: e.target.value })} disabled={modalValue === 'View' ? true : false} />
                            </Box>
                            <Box className={styles.model_popup_form}>
                                <Box className={styles.model_popup_head}>Company Name
                                </Box>
                                <Textarea className={styles.model_popup_input} value={checkedValue.Company_Name} placeholder="Company Name" onChange={(e) => setCheckedValue({ ...checkedValue, Company_Name: e.target.value })} disabled={modalValue === 'View' ? true : false} />
                            </Box>
                            <Box className={styles.model_popup_form}>
                                <Box className={styles.model_popup_head}>Department
                                </Box>
                                <Textarea className={styles.model_popup_input} value={checkedValue.Department} placeholder="Department" onChange={(e) => setCheckedValue({ ...checkedValue, Department: e.target.value })} disabled={modalValue === 'View' ? true : false} />
                            </Box>
                            <Box className={styles.model_popup_form}>
                                <Box className={styles.model_popup_head}>Website
                                </Box>
                                <Textarea className={styles.model_popup_input} value={checkedValue.Website} placeholder="Website" onChange={(e) => setCheckedValue({ ...checkedValue, Website: e.target.value })} disabled={modalValue === 'View' ? true : false} />
                            </Box>
                            <Box className={styles.model_popup_form}>
                                <Box className={styles.model_popup_head}>Recipient Email
                                </Box>
                                <Textarea className={styles.model_popup_input} value={checkedValue.Recipient_Email} placeholder="Recipient Email" onChange={(e) => setCheckedValue({ ...checkedValue, Recipient_Email: e.target.value })} disabled={modalValue === 'View' ? true : false} />
                            </Box>
                            <Box className={styles.model_popup_form}>
                                <Box className={styles.model_popup_head}>Subject
                                </Box>
                                <Textarea className={styles.model_popup_input} value={checkedValue.Subject} placeholder="Subject" onChange={(e) => setCheckedValue({ ...checkedValue, Subject: e.target.value })} disabled={modalValue === 'View' ? true : false} />
                            </Box>
                            <Box sx={{
                                display: 'flex', justifyContent: 'flex-end', gap: '10px', paddingTop: '10px'
                            }}>
                                <Button variant="outlined" onClick={() => handleCloseModal()}>Close</Button>
                                {modalValue === 'Edit' && <Button variant="contained" onClick={() => handleValueEdit({ modalValue })}>{modalValue}</Button>}
                            </Box>
                        </ModalContent>
                    </Modal>
                    {(emailCampaign || allUser) && (<Select
                        MenuProps={{ classes: { paper: styles.select_pagination } }}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": { border: 'none !important' },
                            m: 1,
                            minWidth: 80,
                            width: '70px',
                            height: '38px',
                            border: '1px solid var(--border-color) !important',
                            fontFamily: 'var(--primary-font-family)',
                        }}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(e.target.value)
                            limitData(e.target.value);
                            setCursor(1);
                        }}
                    >
                        {pageSizeArr.map((item, i) => (
                            <MenuItem className={styles.pagination_item} key={i} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>)}
                    <Box style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            onClick={() => handlePageHandler('decrement')}
                            disabled={cursors === 1}
                        >
                            <ArrowBackIosIcon />
                        </Button>
                        <Button
                            onClick={() => handlePageHandler('increment')}
                            disabled={!nextpage}
                        >
                            <ArrowForwardIosIcon />
                        </Button>
                    </Box>
                </Box>
            </Box >}
            <Box className={styles.table_container_wrap}>
                <Box
                    className={
                        styles.table_container +
                        ' ' +
                        (body?.length === 0 ? styles.table_container_no_data : '')
                    }
                >
                    {body?.length === 0 && localValue?.length === 0 && (
                        <Box
                            style={{
                                position: ' absolute',
                                translate: ' -50% -50%',
                                top: '50%',
                                left: '50%',
                                zIndex: 3,
                            }}
                        >
                            No data found !
                        </Box>
                    )}
                    <Table
                        sx={{ width: '100%', border: '1.5px solid rgba(231, 234, 243, 0.7)' }}
                        aria-label='simple table'
                        padding='none'
                        stickyHeader
                    >
                        <TableHead style={{ backgroundColor: '#fafafd' }}>
                            <TableRow>
                                {emailCampaign && (<TableCell sx={{ zIndex: 10, maxWidth: localValue?.length <= 1 && "1%" }}
                                // style={{ background: sdkPath === 'sdk' && !body?.length && tool_tip ? '#13214440' : '#fafafd', fontFamily: 'var(--primary-font-family)', width: 100, borderBottom: '1px solid var(--border-color)' }}
                                >
                                    <Checkbox disableRipple sx={{ transform: 'scale(0.9)', color: 'var(--border-color)' }}
                                        checked={isAllChecked} onChange={handleCheckAll} icon={<Box sx={{
                                            width: 20,
                                            height: 20,
                                            borderRadius: '5px',
                                            border: '1px solid var(--border-color)',

                                            // zIndex: 10,
                                        }}></Box>}
                                        checkedIcon={<CheckedIcon />} />
                                </TableCell>)}
                                {head?.map((item, i) => (
                                    <TableCell
                                        align='left'
                                        key={i}
                                        style={{ color: 'var(--table-header-font)', textAlign: !emailCampaign ? "center" : 'none', fontWeight: 400, fontSize: '12px', background: '#f8fafd', width: 300, borderBottom: '1px solid var(--border-color)', textTransform: 'uppercase' }}
                                    >
                                        {item}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {body?.length > 0 && (
                            <TableBody className={styles.table_body}>
                                {body?.map((item, i) => {
                                    const checkMapId = item.Id || item.Template_Id;
                                    return (
                                        <TableRow
                                            key={i}
                                            sx={{
                                                '&:hover': { backgroundColor: 'rgba(189,197,209,.2)' },
                                            }}
                                        >
                                            {emailCampaign && (<TableCell sx={{ border: 'unset', width: 20, maxWidth: localValue?.length <= 2 && "1%" }} component='th' scope='row'>
                                                <Checkbox
                                                    disableRipple
                                                    sx={{ transform: 'scale(0.9)', color: 'var(--border-color)', fontSize: '16px' }}
                                                    checked={checkedId.includes(checkMapId)}
                                                    icon={<Box sx={{
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: '5px',
                                                        border: '1px solid var(--border-color)'
                                                    }}></Box>}
                                                    checkedIcon={<CheckedIcon />}
                                                    onClick={(e) => {
                                                        const isChecked = e.target.checked;
                                                        setTemplateList({ ...item });

                                                        if (checkedId.includes(checkMapId)) {
                                                            if (!isChecked) {
                                                                setCheckedId((cur) => cur.filter((o) => o !== checkMapId));
                                                            }
                                                        } else {
                                                            setCheckedId((cur) => [...cur, checkMapId]);
                                                        }

                                                    }}
                                                />
                                            </TableCell>)}
                                            {keys?.map((key, i) => {

                                                let date = '';
                                                let over_flow = ['Email_Id', 'Template_Name', 'Properties', 'Sign_Journey', 'Document_Name', 'Information', 'User_Setup_Guide', 'Modified_Time', 'Created_Time', "Subject", "Body"];
                                                if (key === 'Modified_Time' || key === 'Created_Time' || key === 'Start_Time') {
                                                    date = DateFormat(item[key])
                                                }
                                                return (
                                                    <TableCell
                                                        sx={{ textAlign: !emailCampaign ? "center" : 'none', paddingRight: !emailCampaign ? "0px" : '10px !important' }}
                                                        key={i}
                                                        ign='left'
                                                    >
                                                        <Tooltip title={over_flow.indexOf(key) !== -1 && item[key]} key={i} onClick={(e) => {


                                                            if (emailCampaign) {
                                                                const isChecked = e.target.checked;
                                                                setTemplateList({ ...item })
                                                                handleUpdateModal('Edit', checkMapId);
                                                                if (checkedId.includes(checkMapId)) {
                                                                    if (!isChecked) {
                                                                        setCheckedId((cur) => cur.filter((o) => o !== checkMapId));
                                                                    }
                                                                } else {
                                                                    setCheckedId((cur) => [...cur, checkMapId]);
                                                                }
                                                            }

                                                        }}>
                                                            <div style={{ cursor: 'pointer', color: 'var(--table-font-color)', fontFamily: 'var(--primary-font-family) !important', width: over_flow.indexOf(key) !== -1 && key === "Body" ? head.length <= 5 ? '100%' : 400 : 200, maxWidth: over_flow.indexOf(key) !== -1 && key === "Body" ? head.length <= 5 ? '100%' : 1000 : 200, textOverflow: over_flow.indexOf(key) !== -1 && 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                {emailCampaign ? key === 'Modified_Time' || key === 'Created_Time' || key === 'Start_Time' ? date : item[key] ? item[key] : '-' : item[key] ? item[key] : '-'}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </Box>
            </Box>
        </Box >
    );
};

export default AdminCustomTable;
