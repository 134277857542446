import React, { useState } from 'react';
import styles from './table.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {
  Box,
  Button,
  InputAdornment,
  Select,
  TextField,
  Typography,
  MenuItem,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect } from 'react';
import moment from 'moment';
import CheckedIcon from '../checkedIcon';
import '../../pages/WelcomePage/WelcomePage.css';
import { DeleteModal } from '../DeleteModal';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


const CustomTable = ({
  editButton,
  head,
  body,
  onDelete,
  pageIndex,
  getCheckedId,
  onSearch,
  onRowClick,
  keys,
  totalRecordCount,
  getPageIndexSize,
  handleEditTemplate,
  searchName,
  pageDeleteIndex,
  pageSetIndex,
  tool_tip,
  rolePermissions,
  checkAdmin
}) => {
  const pageSizeArr = [10, 20, 30, 40, 50];
  const [pageSize, setPageSize] = useState(10);
  const [pageIdx, setPageIdx] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedId, setCheckedId] = useState([]);
  const [templateList, setTemplateList] = useState({});
  const [cursor, setCursor] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const mobile_view = useMediaQuery('(max-width: 600px)');
  const location = useLocation();
  const sdkPath = location.pathname.split('/')[1];

  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);

  const resetId = () => {
    setIsAllChecked(false);
    setCheckedId([]);
  };

  // useEffect(() => {
  //   setRoles(show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin'))
  // }, [show_integeration])

  useEffect(() => {
    if (pageDeleteIndex) {
      setPageIdx(pageIdx - 1);
      pageSetIndex(false);
    }
  }, [pageDeleteIndex]);

  const handlePageHandler = (type) => {
    if (type === 'increment') {
      setCursor(cursor + 1);
      setPageIdx((cur) => cur + 1);
      handlePageIdx(pageIdx + 1, pageSize);
    } else if (type === 'decrement') {
      setPageIdx((cur) => cur - 1);
      setCursor(cursor - 1);
      handlePageIdx(pageIdx - 1, pageSize);
    }
  };

  const handlePageIdx = (pageIdx, pageSize) => {
    if (pageIdx !== 0 && totalRecordCount < pageSize) {
      let idx = 0;
      getPageIndexSize({ idx, pageSize });
    } else {
      getPageIndexSize({ pageIdx, pageSize });
    }
  };

  useEffect(() => {
    getCheckedId(checkedId);

    if (body.length && checkedId.length === body.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [checkedId]);

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    setIsAllChecked(isChecked);
    if (isChecked) {
      setCheckedId(body.map((item) => item.Id));
    } else {
      setCheckedId([]);
    }
  };

  const sliceFrom = totalRecordCount < pageSize ? pageIdx === 0 ? pageIdx + 1 : pageIdx : pageIdx * pageSize + 1;
  const sliceTo = totalRecordCount < pageSize ? pageSize : pageIdx * pageSize + pageSize;

  const DateFormat = (unix) => {
    let getDate = moment(new Date(unix));
    let startOfToday = moment().startOf('day');
    let startOfDate = moment(getDate).startOf('day');
    let daysDiff = startOfDate.diff(startOfToday, 'days');
    let days = {
      '0': 'Today',
      '-1': 'Yesterday',
      '1': 'Tomorrow'
    };

    if (Math.abs(daysDiff) <= 1) {
      let day = days[daysDiff];
      let time = moment(new Date(unix)).format('LT')
      return day + ' ' + time;
    } else {
      return moment(new Date(unix)).format('DD/MM/YYYY LT');
    }
  };

  let show_banner = show_integeration?.show_banner_info?.length && JSON.parse(show_integeration?.show_banner_info)[0]?.banner_details?.MONDAY_WELCOME;
  return (
    <Box className={styles.table_wrap}>
      <Box className={styles.table_nav}>
        <Box className={styles.nav_item} sx={{ width: '40%', gap: '15px' }}>
          {/* <Box className={styles.total_record}>Total Record {totalRecordCount ?? 0}</Box> */}
          <Box className={styles.search_box}>
            <Box style={{ position: 'relative', zIndex: '1', marginLeft: '10px' }}>
              <img src={process.env.REACT_APP_IMAGE_URL + 'ToggleSvg/search.svg'} width='12px' height='12px' alt='searchIcon' />
            </Box>
            <TextField
              onChange={(e) => onSearch({ value: e.target.value, pageSize, pageIdx, setCheckedId })}
              sx={{
                maxWidth: mobile_view ? '200px' : '300px',
                '& input': { fontSize: '13px', padding: '9px 10px 11px 33px' }, position: 'absolute',
                "& input:hover": {
                  backgroundColor: "#f6f7fa"
                }
              }}
              className={styles.search_bar}
              placeholder={searchName}
              fullWidth
              InputProps={{ disableUnderline: true }}
              type="search"
              variant='standard'
            />
          </Box>
        </Box>

        <Box className={styles.nav_item} sx={{ gap: mobile_view ? '5px' : '15px' }}>
          {editButton && (checkAdmin || rolePermissions.create === 1) &&
            <>
              <Button
                variant='outlined'
                style={{
                  cursor: !checkedId.length ? 'not-allowed' : 'pointer',
                  color: '#23ab94',
                  borderColor: '#23ab94',
                  textTransform: 'unset',
                }}
                startIcon={<img src={process.env.REACT_APP_IMAGE_URL + 'ToggleSvg/editIcon-active.png'} width='15px' height='15px' alt='editIcon'
                  style={{ filter: 'invert(55%) sepia(55%) saturate(575%) hue-rotate(120deg) brightness(91%) contrast(84%)' }} />}
                onClick={() => handleEditTemplate(templateList)}
              >
                Edit as new
              </Button>
            </>
          }
          {(checkAdmin || rolePermissions.delete === 1) &&
            <Button
              disabled={!checkedId.length}
              style={{
                textTransform: 'unset',
                cursor: !checkedId.length ? 'not-allowed' : 'pointer',
                borderColor: ' #ed4c78',
                color: ' #ed4c78',
                pointerEvents: 'auto',
                fontFamily: 'var(--primary-font-family)'
              }}
              variant='outlined'
              color='error'
              startIcon={<DeleteIcon fontSize={'10px'} fontcolor={"#ed4c78"} />}
              onClick={() => setShowModal(true)}
            >
              Delete
            </Button>}
          <DeleteModal
            showModal={showModal}
            closeModal={(val) => setShowModal(val)}
            handleDelete={() => checkedId.length > 0 && [onDelete(null, resetId, pageSize, cursor), setShowModal(false)]}
          />
          <Select
            MenuProps={{ classes: { paper: styles.select_pagination } }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": { border: 'none !important' },
              m: '1px 0 1px 1px',
              minWidth: 80,
              width: '70px',
              height: '38px',
              border: '1px solid var(--border-color) !important',
              fontFamily: 'var(--primary-font-family)',
            }}
            value={pageSize}
            onChange={(e) => {
              setPageSize(e.target.value);
              setCursor(1);
              setPageIdx(0);
              handlePageIdx(0, e.target.value);
            }}
          >
            {pageSizeArr.map((item, i) => (
              <MenuItem className={styles.pagination_item} key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <Box sx={{pr: '10px'}}>of {totalRecordCount ?? 0}</Box>

          <Typography style={{ whiteSpace: 'nowrap', fontFamily: 'var(--primary-font-family)' }}>
            {`${sliceFrom} - ${sliceTo}`}
          </Typography>
          <Box style={{ display: 'flex', gap: '5px' }}>
            <IconButton
              onClick={() => handlePageHandler('decrement')}
              sx={{ padding: '4px !important' }}
              disabled={pageIdx < 1 || pageSize > totalRecordCount}
            >
              <ArrowBackIosIcon className={styles.arrow_icons} />
            </IconButton>
            <IconButton
              onClick={() => handlePageHandler('increment')}
              sx={{ padding: '4px !important' }}
              disabled={totalRecordCount < pageIdx * pageSize + pageSize + 1}
            >
              <ArrowForwardIosIcon className={styles.arrow_icons} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box className={styles.table_container_wrap}>
        <Box
          className={
            styles.table_container +
            ' ' +
            (body?.length === 0 ? styles.table_container_no_data : '')
          }
        >
          {body?.length === 0 && (
            <Box
              style={{
                position: ' absolute',
                translate: ' -50% -50%',
                top: '50%',
                left: '50%',
                zIndex: 3,
              }}
            >
              No data found !
            </Box>
          )}

          <Table
            sx={{ width: '100%', border: '1.5px solid rgba(231, 234, 243, 0.7)' }}
            aria-label='simple table'
            padding='none'
            stickyHeader
          >
            <TableHead style={{ backgroundColor: '#fafafd' }}>
              <TableRow>
                <TableCell
                  style={{ background: sdkPath === 'sdk' && show_banner && tool_tip ? '#13214440' : '#fafafd', fontFamily: 'var(--primary-font-family)', width: 50, borderBottom: '1px solid var(--border-color)' }}
                >
                  <Checkbox disableRipple sx={{ transform: 'scale(0.9)', color: 'var(--border-color)' }}
                    checked={isAllChecked} onChange={handleCheckAll} icon={<Box sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '5px',
                      border: '1px solid var(--border-color)'
                    }}></Box>}
                    checkedIcon={<CheckedIcon />} />
                </TableCell>
                {head?.map((item, i) => (
                  <TableCell
                    align='left'
                    key={i}
                    style={{ color: 'var(--table-header-font)', fontWeight: 400, fontSize: '12px', background: sdkPath === 'sdk' && show_banner && tool_tip ? '#13214440' : '#f8fafd', width: 300, borderBottom: '1px solid var(--border-color)', textTransform: 'uppercase' }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {body?.length > 0 && (
              <TableBody className={styles.table_body}>
                {body?.map((item, i) => {
                  const checkMapId = item.Id || item.Template_Id;
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        '&:hover': { backgroundColor: 'rgba(189,197,209,.2)' },
                      }}
                    >
                      <TableCell sx={{ border: 'unset' }} component='th' scope='row'>
                        <Checkbox
                          disableRipple
                          sx={{ transform: 'scale(0.9)', color: 'var(--border-color)', fontSize: '16px' }}
                          checked={checkedId.includes(checkMapId)}
                          icon={<Box sx={{
                            width: 20,
                            height: 20,
                            borderRadius: '5px',
                            border: '1px solid var(--border-color)'
                          }}></Box>}
                          checkedIcon={<CheckedIcon />}
                          onClick={(e) => {
                            const isChecked = e.target.checked;
                            setTemplateList({ ...item })
                            if (checkedId.includes(checkMapId)) {
                              if (!isChecked) {
                                setCheckedId((cur) => cur.filter((o) => o !== checkMapId));
                              }
                            } else {
                              setCheckedId((cur) => [...cur, checkMapId]);
                            }
                          }}
                        />
                      </TableCell>

                      {keys?.map((key, i) => {
                        let date = '';
                        let over_flow = ['Email_Id', 'Template_Name'];
                        if (key === 'Modified_Time' || key === 'Created_Time') {
                          date = DateFormat(item[key])
                        }
                        return (
                          <TableCell
                            key={i}
                            ign='left'
                            onClick={() => onRowClick({ ...item })}
                          >
                            <Tooltip title={over_flow.indexOf(key) !== -1 && item[key]} key={i}>
                              <div style={{ cursor: 'pointer', color: 'var(--table-font-color)', fontFamily: 'var(--primary-font-family) !important', width: over_flow.indexOf(key) !== -1 && 200, maxWidth: over_flow.indexOf(key) !== -1 && 200, textOverflow: over_flow.indexOf(key) !== -1 && 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textTransform: item[key] === 'Monday' ? 'lowercase' : 'none' }}>
                                {key === 'Modified_Time' || key === 'Created_Time' ? date : item[key] ? item[key] : '-'}
                              </div>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </Box>
      </Box>
    </Box >
  );
};

export default CustomTable;
