import React, { useEffect, useRef, useState } from 'react'
import styles from "./Filter.module.css";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';

const Filter = ({ filterExpand, orgtHead, filterdata }) => {
    const [expand, setExpand] = useState(false);
    const [filters, setfilters] = useState([{ id: 1, columns: '', condition: '=', value: '', operator: 'and' }])
    const [isHovered, setIsHovered] = useState(false);


    useEffect(() => {
        const value = filters;
        value[0].columns = orgtHead[0]
        setfilters(value)
    }, [orgtHead[0]]);

    const handleSetColumns = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, columns: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleSetCondition = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, condition: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleSetValue = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, value: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleSetOperator = (id, value) => {
        const updatedInputs = filters.map((filter) =>
            filter.id === id ? { ...filter, operator: value } : filter
        );
        setfilters(updatedInputs);
    }

    const handleAddfilter = (e) => {
        const newFilter = { id: filters.length + 1, columns: orgtHead[0], condition: "=", value: '', operator: "and" };
        setfilters([...filters, newFilter]);
    }

    const handleRemoveFilter = (id) => {
        const updatedInputs = filters.filter((filter) => filter.id !== id);
        setfilters(updatedInputs);
    }

    const sendDataToTable = () => {   //propsdata
        filterdata(filters)
        setExpand(false);
    }

    const clearFilter = () => {
        setExpand(false);
        setfilters([{ id: 1, columns: orgtHead[0], condition: '=', value: '', operator: 'and' }])
        filterdata([])
    }
    return (
        <div>
            <div className={filterExpand ? expand ? styles.filter_close : styles.filter_open : styles.filter_hide} >
                <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => setExpand(!expand)} className={styles.filter_top}>
                    {isHovered ? expand ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon /> : <FilterAltIcon />}
                </div>
                <div className={styles.top_selecters}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}><div>Filter</div><div onClick={() => setExpand(!expand)}><ClearIcon /></div></div>
                    <div className={styles.filters_array}>{filters.map((filter) => (
                        <div key={filter.id} className={styles.selectors}>
                            <FormControl size="small" >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter.columns}
                                    onChange={(e) => handleSetColumns(filter.id, e.target.value)}
                                    style={{ height: 35, width: '170px' }}
                                >
                                    {orgtHead?.map((head, i) => (<MenuItem key={i} value={head}>{head}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <FormControl size="small" >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter.condition}
                                    onChange={(e) => handleSetCondition(filter.id, e.target.value)}
                                    style={{ height: 35, width: '170px' }}
                                >
                                    <MenuItem value={"="}>EQUAL</MenuItem>
                                    <MenuItem value={"!="}>NOT EQUAL</MenuItem>
                                    <MenuItem value={"<"}>LESS THAN</MenuItem>
                                    <MenuItem value={">"}>GREATER THAN</MenuItem>
                                    <MenuItem value={"<="}>LESS THAN OR EQUAL</MenuItem>
                                    <MenuItem value={">="}>GREATER THAN OR EQUAL</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size="small" >
                                <TextField sx={{ width: '170px' }} style={{ height: 35 }} onChange={(e) => handleSetValue(filter.id, e.target.value)} value={filters.value} placeholder='Value' id="outlined-basic" variant="outlined" />
                            </FormControl>
                            <FormControl size="small" >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter.operator}
                                    onChange={(e) => handleSetOperator(filter.id, e.target.value)}
                                    style={{ height: 35, width: '170px' }}
                                >
                                    <MenuItem value={"and"}>AND</MenuItem>
                                    <MenuItem value={"or"}>OR</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size="small" >
                                {filters.length > 1 && <Button variant="contained" onClick={() => handleRemoveFilter(filter.id)}>Delete</Button>}
                            </FormControl>
                        </div>))}</div>
                    <div className={styles.filter_sub_button}>
                        <div><Button variant="contained" onClick={handleAddfilter}>Add</Button></div>
                        <div style={{ display: 'flex', gap: '10px' }}><Button variant="contained"
                            onClick={() => clearFilter()}
                        >Clear</Button>
                            <Button variant="contained" onClick={sendDataToTable}>Apply</Button></div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Filter