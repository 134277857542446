import axios from "axios";

export const getUploadChannel = (data, resp, error) => {
    let options = data?.cursor ? { org_id: data.Org_Id, channel_type: data.channel_type, channel_id: data.channel_id, cursor: data?.cursor, path: data.path } :
        { org_id: data.Org_Id, channel_type: data.channel_type, channel_id: data.channel_id, path: data.path };
    axios.get(process.env.REACT_APP_GETFILES, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: options })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};

export const getChannelConnect = (data, resp, error) => {
    let options = { org_id: data.org_id, channel_type: data.channel_type };
    axios.get(process.env.REACT_APP_DROPBOX, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: options })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};

export const getChannelFiles = (data, resp, error) => {
    let options = { org_id: data.org_id, channel_type: data.channel_type };
    axios.get(process.env.REACT_APP_CHANNEL, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: options })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        })
};