import { upload_action } from '../../store/uploadDocument/uploadAction';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { welcome_img_action } from '../../store/welcomeImage/welcomeImgAction';
import { toast } from 'react-toastify';
import { integration_action } from '../../store/integrations/integrationAction';
import { getInit, getUstat } from '../../Api/UserGetApi';
import { persistStore } from 'redux-persist';
import store from '../../store/store';
import { digest_action } from '../../store/digest/digest_action';

const OrgInit = ({ prePath, isFetchedSdkhome, isFetched }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orgId = prePath?.split('/')[1]?.split('c')[1];
  const sdkhome = prePath?.split('#')[0]?.split('/')[1];

  useEffect(() => {
    if (sdkhome === 'sdkhome') {
      navigate('/sdkhome');
      isFetchedSdkhome(true);
    } else {
      fetchSignInit();
    }
  }, []);

  const fetchSignInit = () => {
    let param = {};
    dispatch(toggleLoader(true));
    getUstat(param, (res) => {
      if (res?.data) {
        handleInitHandler();
      } else {
        window.open(process.env.REACT_APP_SIGUP, "_self");
      }
    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
    });
  };

  const handleInitHandler = () => {
    let param = orgId ? { org_id: orgId } : {}
    const persistor = persistStore(store);
    persistor.purge();
    dispatch(digest_action(null));
    dispatch(welcome_img_action(null));
    dispatch(upload_action(null));
    getInit(param, (response) => {
      isFetched(true);
      dispatch(toggleLoader(false));
      dispatch(welcome_img_action(response?.data));
      let list = { integration_information: response?.data?.integration_information, is_connected: true }
      dispatch(integration_action(list));
      let user_info = response?.data?.user_information;
      let data = {
        Email: user_info?.Email_Id,
        FirstName: user_info?.First_Name,
        LastName: user_info?.Last_Name,
        OrgId: response?.data?.default_organizations?.Id,
        Status: user_info?.Status,
        RoleName: user_info?.userRole
      };
      dispatch(upload_action(data));
      const id = data?.OrgId ? `c${data?.OrgId}` : '';
      let url_ = prePath?.replace(prePath?.split('/')[1], id);
      if (prePath === '/') {
        navigate(`${id}/home`, { state: { switch_org_reload: true } });
      } else {
        navigate(url_, { state: { switch_org_reload: true } });
      };
    }, (e) => {
      dispatch(toggleLoader(false));
      if (e?.response?.data === 'org_not_found' && !orgId) {
        window.open(process.env.REACT_APP_ACCOUNT, '_self');
      } else if (e?.response?.data === 'org_not_found' && orgId) {
        window.open(process.env.REACT_APP_REDIRECT_ORG_NOT_FOUNT, '_self');
      } else if (e?.response?.data?.org_id === 'org_id is invalid') {
        window.location.reload(true);
      }
    });
  };
};

export default OrgInit;