import { Select } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import styles from './CustomSelect.module.css';

const CustomSelect = ({ element = <></>, children, condition }) => {
    return (
        <>
            {condition === true ? (
                <Box className={styles.more_condition_icon_button}>
                    {element}
                    <Select
                        MenuProps={{ classes: { paper: styles.select_condition_more_options } }}
                        style={{ opacity: '0', position: 'absolute' }}
                        value=''
                    >
                        {children}
                    </Select>
                </Box>
            ) : (
                <Box className={styles.more_icon_button}>
                    {element}
                    <Select
                        MenuProps={{ classes: { paper: styles.select_more_options } }}
                        style={{ opacity: '0', position: 'absolute' }}
                        value=''
                    >
                        {children}
                    </Select>
                </Box>
            )}
        </>
    );
};

export default CustomSelect;
