import React, { useEffect, useState } from 'react';
import './WelcomePage.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CardActionArea, CardContent, CardMedia, IconButton, MenuItem, Modal, Select, TextField, Tooltip, Typography } from '@mui/material';
import { document_action } from '../../store/documentDetails/document_action';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomSelect from '../../component/CustomSelect/CustomSelect';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { Navigation } from '../../utils/handleNavigation';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Card from '@mui/material/Card';
import moment from 'moment';
import { template_preview_action } from '../../store/templatePreview/templatePreviewAction';
import { DeleteModal } from '../../component/DeleteModal';
import { getDocumentDelete } from '../../Api/DocumentDeleteApi';
import { Elderly } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Skeleton from '@mui/material/Skeleton';
import ShareIcon from '@mui/icons-material/Share';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import SourceIcon from '@mui/icons-material/Source';

const options = [
  { value: 'Created by me', label: 'Created by me' },
  { value: 'Create ', label: 'Create' },
  { value: 'Created', label: 'Created' },
];

const navigatePages = ['File Upload', 'AITemplate', 'New Folder'];
const skeleton_options = ['1', '2', '3', '4', '5', '6', '7', '8'];

const folder_options = [
  { name: 'Share folder', icon: <ShareIcon sx={{ fontSize: 15, color: 'var(--primary)' }} /> },
  { name: 'Move to', icon: <DriveFileMoveIcon sx={{ fontSize: 15, color: 'var(--primary)' }} /> },
  { name: 'Rename', icon: <DriveFileRenameOutlineIcon sx={{ fontSize: 15, color: 'var(--primary)' }} /> },
  { name: 'Delete', icon: <DeleteIcon sx={{ fontSize: 15, color: '#ed4c78' }} /> }
];

const WelcomePage = () => {
  const pathNav = Navigation();

  const dispatch = useDispatch();
  const [documentList, setDocumentList] = useState([]);
  const [createOption, setCreateOption] = useState('Created by me');
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [tempImg, setTempImg] = useState(false);
  const [mapedDoc, setMapedDoc] = useState([]);
  const [mapedSign, setMapedSign] = useState([]);
  const [welcomeImg, setWelcomeImg] = useState(false);
  const [rename_doc, setRename_doc] = useState({});
  const [open, setOpen] = useState(false);
  const [folder, setFolder] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [folderOpen, setFolderOpen] = useState(false);

  const documents = useSelector((state) => state.document.value);
  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');

  useEffect(() => {
    if (JSON.parse(show_integeration?.plans_info)[0]?.plan_flags?.AI_TEMPLATE) {
      if (!JSON.parse(show_integeration?.show_features)[0]?.features?.AI_TEMPLATE && JSON.parse(show_integeration?.show_features)[0]?.features?.hasOwnProperty('AI_TEMPLATE')) navigatePages.splice(1, 1);
    } else {
      navigatePages.splice(1, 1);
    }
  }, []);

  const upload = (key) => {
    switch (key) {
      case 'AITemplate':
        pathNav('/CreateTemplates');
        break;
      case 'File Upload':
        pathNav('/upload');
        break;
      case 'New Folder':
        setFolderOpen(true);
        break;
      default:
        pathNav('/upload');
        break;
    }
  };

  // const handleFilterChange = (e) => {
  //   setCreateOption(e.target.value)
  // };

  useEffect(() => {
    fetchDocument();
  }, [documents]);

  const fetchDocument = () => {
    dispatch(toggleLoader(true));
    let options = { org_id: documents?.OrgId, limit: 100 };
    axios.get(process.env.REACT_APP_DOCUMENT, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: options })
      .then((res) => {
        setDocumentList(res.data.document_list);
        setFolder(res.data.folders);
        dispatch(template_preview_action(null));
        dispatch(document_action(null));
        setWelcomeImg(true);
        // setTimeout(() => {
        //   setTempImg(true);
        // }, 500);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      }).finally(() => dispatch(toggleLoader(false)));
  };

  const handleTemplateHandler = (item) => {
    dispatch(toggleLoader(true));
    dispatch(document_action({ item }));
    pathNav(`/document/${item.Id}`);
  };

  const handleDeleteDoc = (Id) => {
    dispatch(toggleLoader(true))
    let data_obj = {
      org_id: documents?.OrgId,
      ids: [Id.toString()],
      limit: 100,
      cursor: 1,
      is_confirm: false
    };

    let data = JSON.stringify(data_obj);
    handleDocumentDeleteApi(data, Id);
  };

  const handleDocumentDeleteApi = (data, Id) => {
    getDocumentDelete(data, (res) => {
      setShowModal(true);
      let mapedDocument = res.data;
      if (mapedDocument?.has_mapped_template) {
        setMapedDoc(res?.data?.mapped_templates);
      }
      if (mapedDocument?.has_mapped_signs) {
        setMapedSign(res?.data?.mapped_signs);
      }
      dispatch(toggleLoader(false));
    }, (err) => {
      setShowModal(true);
      toast.error(err.message);
      dispatch(toggleLoader(false));
      setShowModal(false);
    });
  }

  const handleConfirmDelete = (Id) => {
    let data_obj = {
      org_id: documents?.OrgId,
      ids: [Id?.toString()],
      limit: 100,
      cursor: 1,
      is_confirm: true
    };

    dispatch(toggleLoader(true))
    getDocumentDelete(JSON.stringify(data_obj), (res) => {
      setDocumentList(res.data.document_list);
      setWelcomeImg(true);
      setShowModal(false);
      dispatch(toggleLoader(false));
    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
      setShowModal(false);
    });
  };

  const handleCloseModal = (val) => {
    setShowModal(val);
    setMapedDoc([]);
    setMapedSign([]);
  };

  const handleDownLoad = (fileId, fileName) => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_DOCUMENTDOWNLOAD, { params: { file_id: fileId, org_id: documents?.OrgId }, responseType: 'arraybuffer', withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        let data = new Blob([res?.data], { type: "application/pdf" });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(data);
        link.download = fileName?.split('.')[0] + '.pdf';
        link.click();
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
        toast.error(err.message);
      })
  };

  const handleNameChange = (e, key) => {
    let data = { ...rename_doc };
    data[key] = e.target.value;
    setRename_doc(data);
  };

  const handleChangeDocName = () => {
    let validateDoc = documentList?.find(s => s?.Id === rename_doc?.doc_id);
    if (validateDoc?.Document_Name !== rename_doc?.doc_name) {
      dispatch(toggleLoader(true));
      let options = { org_id: documents?.OrgId, document_id: rename_doc?.doc_id, document_name: rename_doc?.doc_name };
      axios.put(process.env.REACT_APP_DOCUMENT, options, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          setOpen(false);
          setRename_doc({});
          if (res?.data?.success) fetchDocument();
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    } else {
      setOpen(false);
      setRename_doc({});
    }
  };

  const handleCreateFolder = () => {
    let data = { org_id: documents?.OrgId, product_code: 3, folder_name: folderName };
    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_FOLDER, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        fetchDocument();
        setFolderOpen(false);
        setFolderName('');
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleFolder = (item, key) => {
    switch (key) {
      case 'Delete':
        let data = { org_id: documents?.OrgId, product_code: 3, folder_id: item?.Id };
        dispatch(toggleLoader(true));
        axios.delete(process.env.REACT_APP_FOLDER, { withCredentials: process.env.REACT_APP_CREDENTIALS, data: data })
          .then((res) => {
            fetchDocument();
            setFolderOpen(false);
            setFolderName('');
            dispatch(toggleLoader(false));
          })
          .catch((err) => {
            toast.error(err.message);
            dispatch(toggleLoader(false));
          });
        break;

      case 'Rename':
        console.log('Rename');
        break;
      default:
        // let data = { org_id: documents?.OrgId, product_code: 3, parent_id: item?.Id, source_id: 1263, type: 1 };
        // dispatch(toggleLoader(true));
        // axios.put(process.env.REACT_APP_FILE_MOVE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        //   .then((res) => {
        //     dispatch(toggleLoader(false));
        //     toast.success(`demo.png has been moved to ${item?.folder_name}`);
        //   })
        //   .catch((err) => {
        //     toast.error(err.message);
        //     dispatch(toggleLoader(false));
        //   });
        console.log('share folder');
        break;
    }
  };

  return (
    <>
      {welcomeImg ?
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box className='my_doc_header' sx={{ padding: '0px 40px' }}>
            <Box className='document-header'>
              <Box className='title'>
                <Box className='my-documents'>My Documents</Box>
              </Box>
            </Box>
            {(admin || show_integeration?.user_permission[1]?.create === 1) && (
              <div className='upload-body'>
                <Button variant='contained' className='upload-button' onClick={() => upload()}>
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/upload-icon.svg'}
                    className='file-upload-svg'
                    alt='fileUpload'
                  />
                  Upload
                </Button>
                <Select
                  value={''}
                  className='upload-document'
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none !important' },
                  }}
                  MenuProps={{ classes: { paper: 'welcome_page_select', border: 'none' } }}
                  onChange={(e) => upload(e.target.value)}
                >
                  {navigatePages?.map((s, i) => (
                    <MenuItem className='file-upload-wrapper' key={i} value={s}>
                      <img
                        src={s === 'New Folder' ? process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/new-folder.svg' : process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/file-upload.svg'}
                        className='file-upload-svg'
                        alt='fileUpload'
                      />
                      {s !== 'AITemplate' ? (
                        <span>{s}</span>
                      ) : (
                        <span className="power-letter">Create AI Template<sup>BETA</sup></span>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </Box>
          <Box className='document-container'>
            <div className='upload'>
              <div className='filter-container'>
                {/* <div className='filter-body'>
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/filterIcon.svg'}
                    alt='filterIcon'
                    className='filter-svg'
                  />
                  <Select sx={{
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: '1px solid rgba(231, 234, 243, 0.70))'
                      },
                      "&:hover fieldset": {
                        border: '1px solid var(--primary)'
                      }
                    }
                  }}
                    className='filter-select'
                    value={createOption}
                    onChange={handleFilterChange}>
                    {options.map((item, i) =>
                      <MenuItem value={item?.value} key={i}>
                        {item?.label}
                      </MenuItem>
                    )}
                  </Select>
                </div> */}
              </div>
            </div>
            {/* <div className='favorites'>Favorites</div> */}
            {!documentList?.length && <Box className='no_documents_found'>No documents found !</Box>}
            <div className='favorites-body'>
              {documentList?.map((item, i) => {
                return (
                  <div className='favorites-list' key={i}>
                    <Card className='thumbnail_body'>
                      {/* <CardActionArea onClick={() => { (admin || show_integeration?.user_permission[1]?.update === 1) && handleTemplateHandler(item) }}> */}
                      <CardActionArea onClick={() => handleTemplateHandler(item)}>
                        <CardContent sx={{ height: '110px' }}>
                          <img src={`${process.env.REACT_APP_THUMBNAIL}?file_id=${item?.File_Id}&org_id=${item?.Org_Id}`} alt='img' className="template-img" />
                        </CardContent>
                        <CardContent style={{ borderTop: '1px solid rgba(231, 234, 243, 0.7' }}>
                          <Tooltip title={item?.Document_Name?.length > 20 ? item?.Document_Name : ''} key={i}>
                            <Typography className='thumnail_name'>
                              {item?.Document_Name}
                            </Typography>
                          </Tooltip>
                          <Typography className='thumbnail_date'>
                            Created At {moment(new Date(item?.Created_Time)).format('DD/MM/YYYY')}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    <div className='delete-doc'>
                      <CustomSelect
                        element={
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        }
                        condition={true}>
                        return (
                        <MenuItem
                          sx={{
                            margin: '5px',
                            alignItems: 'center',
                            "&:hover": {
                              backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                              borderRadius: '6px',
                            }
                          }}
                          onClick={() => [setRename_doc({ doc_id: item.Id, doc_name: item?.Document_Name }), setOpen(true)]}
                        >
                          <DriveFileRenameOutlineIcon sx={{ fontSize: 15, color: 'var(--primary)' }} />
                          <Button className='document_options' >
                            Rename
                          </Button>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            margin: '5px',
                            alignItems: 'center',
                            "&:hover": {
                              backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                              borderRadius: '6px',
                            }
                          }}
                          onClick={() => handleDownLoad(item.File_Id, item?.Document_Name)}
                        >
                          <FileDownloadIcon sx={{ fontSize: 15, color: 'var(--primary)' }} />
                          <Button className='document_options' >
                            Download
                          </Button>
                        </MenuItem>
                        {(admin || show_integeration?.user_permission[1]?.delete === 1) && (
                          <MenuItem
                            sx={{
                              margin: '5px',
                              alignItems: 'center',
                              "&:hover": {
                                backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                borderRadius: '6px'
                              }
                            }}
                            onClick={() => [setDeleteId({ id: item.Id, orgId: item.Org_Id }, handleDeleteDoc(item.Id))]}>
                            <DeleteIcon sx={{ fontSize: 15, color: '#ed4c78' }} />
                            <Button color='error' className='document_options'>
                              Delete
                            </Button>
                          </MenuItem>)}
                        );
                      </CustomSelect>
                    </div>
                  </div>
                );
              })}
              <DeleteModal
                showModal={showModal}
                closeModal={handleCloseModal}
                handleDelete={() => handleConfirmDelete(deleteId?.id)}
                mapped_templates={mapedDoc}
                mapped_signs={mapedSign}
              />
              <Modal
                open={open}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
              >
                <Box className='doc_new_limit_wrap'>
                  <Box className="doc_new_text">Document name</Box>
                  <TextField
                    onChange={(e) => handleNameChange(e, 'doc_name')}
                    value={rename_doc?.doc_name}
                    sx={{
                      minWidth: 220,
                      border: '1px solid var(--border-color)',
                      '& input': { fontSize: '13px', padding: '9px 10px 11px 10px' },
                      "& input:hover": {
                        backgroundColor: "#f6f7fa"
                      }
                    }}
                    placeholder='Document name'
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    variant='standard'
                  />
                  <Box className='doc_rename_btn'>
                    <Button variant='outlined' className='document-delete-cancel' onClick={() => setOpen(false)}>Cancel</Button>
                    <Button variant='contained' className='doc_new_submit_btn' onClick={handleChangeDocName}>Update</Button>
                  </Box>
                </Box>
              </Modal>
              {folder?.length ? <Box className='folder_name'>Folders</Box> : null}
              <Modal
                open={folderOpen}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
              >
                <Box className='doc_new_limit_wrap'>
                  <Box className='doc_new_text'>Create Folder</Box>
                  <IconButton
                    className='close_btn'
                    onClick={() => setFolderOpen(false)}
                  >
                    <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                  </IconButton>
                  <Box sx={{ width: '100%', display: 'flex', margin: '10px 0px', gap: '10px' }}>
                    <TextField
                      onChange={(e) => setFolderName(e.target.value)}
                      value={folderName}
                      sx={{
                        width: 500,
                        border: '1px solid var(--border-color)',
                        '& input': { fontSize: '13px', padding: '9px 10px 11px 10px' },
                        "& input:hover": {
                          backgroundColor: "#f6f7fa"
                        }
                      }}
                      placeholder='create folder'
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      variant='standard'
                    />
                    <Button sx={{ textTransform: 'capitalize' }} onClick={handleCreateFolder} variant='contained'>Create</Button>
                  </Box>
                </Box>
              </Modal>
              <Box className='folder_main'>
                {folder?.map((item, i) => {
                  return (
                    <Box className='folder_border' key={i}>
                      <Box>
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/new-folder.svg'}
                          alt=''
                          width={20}
                          height={20}
                        />
                      </Box>
                      <Box>{item?.Folder_Name}</Box>
                      <Box sx={{ position: 'relative' }}>
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/three-dots.svg'}
                          alt=''
                          width={20}
                          height={20}
                        />
                        <div className='delete-doc'>
                          <CustomSelect
                            condition={true}>
                            {folder_options.map((list, idx) => {
                              return (
                                <MenuItem
                                  key={idx}
                                  sx={{
                                    margin: '5px',
                                    alignItems: 'center',
                                    "&:hover": {
                                      backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                      borderRadius: '6px'
                                    }
                                  }}
                                  onClick={() => handleFolder(item, list.name)}>
                                  {list.icon}
                                  <Button color={list.name === 'Delete' ? 'error' : 'primary'} className='document_options'>
                                    {list.name}
                                  </Button>
                                </MenuItem>
                              )
                            })
                            }
                          </CustomSelect>
                        </div>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </div>
          </Box>
        </Box> :
        <Box className='document-container'>
          {skeleton_options.map((j) =>
            <Box style={{ width: '200px', height: '180px', display: 'inline-block', margin: '1%', marginTop: '100px' }} key={j}>
              <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)' }} animation="wave" variant="rounded" width={"100%"} height={'100%'} />
            </Box>)}
        </Box>}
    </>
  );
};

export default WelcomePage;