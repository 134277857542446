import { Button } from '@mui/material';
import "./PDFView.css";
import JoditEditor from 'jodit-react';
import React, { useRef } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getPdfFile } from '../../Api/ParaphraseApi';
import { pdfData } from '../../store/paraphrase/pdfDataReducer';
import { toast } from 'react-toastify';
import { Navigation } from '../../utils/handleNavigation';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { document_action } from '../../store/documentDetails/document_action';
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowLeftSharp';

const PDFView = ({ steps }) => {
    const pdfValue = useSelector((state) => state?.pdfDetails?.value?.data);
    const pdfdata = useSelector((state) => state?.pdfDatas?.value?.data);
    const documentValue = useSelector((state) => state.document.value);
    const pathNav = Navigation();
    const dispatch = useDispatch();
    const editors = useRef(null);

    const config = {
        "toolbarAdaptive": false,
        "toolbar": false,
        "useSearch": false,
        "toolbar": false,
        "showCharsCounter": false,
        "showWordsCounter": false,
        "showXPathInStatusbar": false,
        "readonly": true,
        "enter": "BR",
        "spellcheck": true,
    };

    const handleDownload = (e) => {
        const formData = new FormData();
        formData.append('file', new Blob([editors.current.value], { type: 'text/html' }), 'filename.html');
        formData.append('org_id', documentValue?.OrgId);

        getPdfFile(
            formData,
            (res) => {
                let resdata = res.data
                dispatch(pdfData({ data: res.data }))
                toast.success("Saved to the Drive ");
                if (e === "view") {
                    dispatch(toggleLoader(true));
                    dispatch(document_action({ item: resdata }));
                    pathNav(`/document/${res.data.Id}`);
                }
                else if (e === "save") {
                    dispatch(toggleLoader(true));
                    // dispatch(document_action({ item: resdata }));
                    pathNav('/myDocuments');
                }
            },
            (err) => {
                toast.error(err.message);
            }
        );
    }

    const handleBack = () => {
        steps((prevValue) => prevValue - 1);
    }
    return (
        <div className='jodit_top'><div className='jodit_table'>
            <JoditEditor
                ref={editors}
                value={pdfValue}
                config={config}
                tabIndex={-1}
            />

        </div>
            <div className={"submit_btn"}>
                <Button
                    variant="outlined"
                    className='save_drive'
                    onClick={handleBack}
                >
                    <ArrowLeftSharpIcon />
                    Back
                </Button>
                <Button
                    variant="outlined"
                    className='save_drive'
                    onClick={() => handleDownload('save')}
                >
                    <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/download.svg`} />
                    Save in Drive
                </Button>
                <Button
                    variant="outlined"
                    className='sent_sign'
                    disableElevation
                    disableRipple
                    onClick={() => handleDownload('view')}
                >
                    <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/send.svg`} />
                    Send to Sign
                </Button>
            </div>
        </div>
    )
}

export default PDFView